import React, { useEffect, useState } from 'react';
import style from './PatientDashboard.scss';
import { connect, ConnectedProps } from 'react-redux';
import { ReduxState } from '../../redux/types';
import { getSelectedPersonVitalOverview } from '../../redux/person/personSelector';
import { getPersonVitalOverviewThunk } from '../../redux/person/personThunks';
import { compose } from 'redux';
import { useNavigate, useParams } from 'react-router';
import { getInitials } from 'utils/util';
import { Text } from '../../components/Typography';
import AddIcon from '@mui/icons-material/Add';
import { Button, Tooltip } from '@mui/material';
import { DropDownMenu } from 'components/DropDownMenu/DropDownMenu';
import { VitalCard } from './components/VitalCard/VitalCard';
import { ComingSoonCard } from 'containers/PatientDashboard/components/ComingSoonCard/ComingSoonCard';
import Insights from './components/Insights/Insights';
import HorizontalTabs from '../../components/HorizontalTabs/HorizontalTabs';
import { getDeviceTimeZoneIdSelector } from '../../redux/device/deviceSelectors';
import { DEFAULT_TIMEZONE_ID, getElapsedTimeDisplay } from '../../utils/time';
import NavTopBarContentWrapper from '../../components/NavTopBarContentWrapper/NavTopBarContentWrapper';
import { normalizeEnumName } from 'utils/content';
import DeletePatient from './components/DeletePatient/DeletePatient';
import { getFixedPointDisplay } from '../../utils/util';
import { getVitalStatusOutOfRangeText } from './PatientDashboardUtil';
import { PatientDashboardInfoDrawer } from 'containers/PatientDashboardInfoDrawer/PatientDashboardInfoDrawer';
import { getDefaultPatientProfileBreadCrumbs, renderBreadCrumbs } from 'utils/breadcrumbs';
import BreadCrumbs from 'components/BreadCrumbs/BreadCrumbs';
import { ChartLineIcon, HeartbeatIcon, MoonStarsIcon, WindIcon } from 'components/Icons';

type PropsFromRedux = ConnectedProps<typeof connectRedux>;
type Props = PropsFromRedux & {};

const PatientDashboard: React.FC<Props> = ({ getDeviceTimeZoneId, selectedPersonVitalOverview, getPersonVitalOverview }) => {
  const params = useParams();
  const patientId = params?.userId ?? '';
  const navigate = useNavigate();

  const personName = selectedPersonVitalOverview?.personName ?? '';
  const personInitials = getInitials(personName);
  const timeZoneId = getDeviceTimeZoneId(patientId) || DEFAULT_TIMEZONE_ID;
  const [showDeletePatientConfirmation, setShowDeletePatientConfirmation] = useState(false);
  const [showViewPatient, setShowViewPatient] = useState(false);
  const [initDone, setInitDone] = useState(false);

  useEffect(() => {
    const fetchPersonVitalOverview = async () => {
      await getPersonVitalOverview(patientId);
      setInitDone(true);
    };

    fetchPersonVitalOverview();
  }, [patientId]);

  const onAddActivityClick = () => {
    navigate(`/patients/${patientId}/newActivity`);
  };

  const onViewPatientDetailClick = () => {
    setShowViewPatient(true);
  };

  const onEditPatientClick = () => {
    navigate(`/patients/${patientId}/editPatient`);
  };

  const onDeletePatientClick = () => {
    setShowDeletePatientConfirmation(true);
  };

  const tabs = [
    { label: 'Insights', content: <Insights personId={patientId} timeZoneId={timeZoneId} /> }
    // { label: 'Activity feed', content: <Feed /> }
  ];

  const defaultCrumbHistory = getDefaultPatientProfileBreadCrumbs(personName);

  return (
    <NavTopBarContentWrapper>
      {initDone && (
        <div className={style.mainContainer}>
          {/* Patient Details and Activity */}
          <div className={style.patientDetailSection}>
            <BreadCrumbs aria-label="breadcrumb">{renderBreadCrumbs(defaultCrumbHistory)}</BreadCrumbs>
            <div className={style.avatarDeviceContainer}>
              <div className={style.avatarAndText}>
                <div className={style.avatar}>
                  <Text variant="xl" className={style.personInitials}>
                    {personInitials}
                  </Text>
                </div>
                <Text variant="2xl">{selectedPersonVitalOverview?.personName}</Text>
              </div>
              <div className={style.patientPresenceTopContainer}>
                <div className={style.deviceIconContainer}>
                  <div className={style.deviceIcon} />
                </div>
                <div className={style.presenceContainer}>
                  <Text variant="sm" className={style.defaultGrayText}>
                    Patient presence
                  </Text>
                  {selectedPersonVitalOverview?.personPresence && selectedPersonVitalOverview?.personPresenceLastReading && (
                    <Text variant="sm">
                      {normalizeEnumName(selectedPersonVitalOverview?.personPresence)} &middot; {getElapsedTimeDisplay(selectedPersonVitalOverview?.personPresenceLastReading)}
                    </Text>
                  )}
                </div>
              </div>
            </div>

            <div className={style.actionsContainer}>
              <Tooltip title="Coming soon" placement="top">
                <div>
                  <Button disabled variant="contained" color="primary" className={style.addButton} onClick={onAddActivityClick} startIcon={<AddIcon className={style.addIcon} />}>
                    Add Activity
                  </Button>
                </div>
              </Tooltip>

              <DropDownMenu
                menuText="Actions"
                additionalButtonClass={style.dropDownButton}
                menuItems={[
                  {
                    text: 'View patient details',
                    onClick: onViewPatientDetailClick
                  },
                  {
                    text: 'Edit',
                    onClick: onEditPatientClick
                  },
                  {
                    text: 'Delete',
                    textClassName: style.redText,
                    onClick: onDeletePatientClick
                  }
                ]}
              />
            </div>
            <DeletePatient
              patientName={personName}
              patientId={patientId}
              onDialogClose={() => setShowDeletePatientConfirmation(false)}
              showDialog={showDeletePatientConfirmation}
            />
            <PatientDashboardInfoDrawer personId={patientId} showDialog={showViewPatient} onDialogClose={() => setShowViewPatient(false)} />
          </div>
          <div className={style.overviewTextContainer}>
            <Text variant="md">Overview for today</Text>
          </div>
          <div className={style.cardsContainer}>
            <VitalCard
              vitalTitle="Heart rate"
              vitalSubtitle={selectedPersonVitalOverview?.heartRateLastReading ? getElapsedTimeDisplay(selectedPersonVitalOverview?.heartRateLastReading) : ''}
              vitalValue={getFixedPointDisplay(selectedPersonVitalOverview?.heartRate)}
              icon={HeartbeatIcon}
              vitalStatus={selectedPersonVitalOverview?.heartRateStatus}
              vitalStatusText={getVitalStatusOutOfRangeText(selectedPersonVitalOverview?.heartRateStatus)}
            />
            <VitalCard
              vitalTitle="Breathing rate"
              vitalSubtitle={selectedPersonVitalOverview?.breathingRateLastReading ? getElapsedTimeDisplay(selectedPersonVitalOverview?.breathingRateLastReading) : ''}
              vitalValue={getFixedPointDisplay(selectedPersonVitalOverview?.breathingRate)}
              icon={WindIcon}
              vitalStatus={selectedPersonVitalOverview?.breathingRateStatus}
              vitalStatusText={getVitalStatusOutOfRangeText(selectedPersonVitalOverview?.breathingRateStatus)}
            />
            <ComingSoonCard title="Sleep efficiency" icon={MoonStarsIcon} />
            <div className={style.sleepOptionsContainer}>
              <ComingSoonCard size="sm" title="Awakenings" icon={ChartLineIcon} />
              <ComingSoonCard size="sm" title="Total sleep" icon={ChartLineIcon} />
            </div>
          </div>

          <HorizontalTabs tabs={tabs} buttonClassName={style.HorizontalTabsButton} tabsContentClassname={style.tabsContent} />
        </div>
      )}
    </NavTopBarContentWrapper>
  );
};

const connectRedux = connect(
  (state: ReduxState) => ({
    getDeviceTimeZoneId: (personId: string) => getDeviceTimeZoneIdSelector(personId, state),
    selectedPersonVitalOverview: getSelectedPersonVitalOverview(state)
  }),
  {
    getPersonVitalOverview: getPersonVitalOverviewThunk
  }
);

export default compose(connectRedux)(PatientDashboard);
