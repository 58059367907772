import { PersonVitalTimeFrame, PersonVitalType, VitalSignsStatus } from '../../../../redux/person/personTypes';
import moment from 'moment-timezone';
import { Color } from 'utils/color';
import exclamationOctagon from 'assets/images/exclamationOctagon.png';
import yellowOctagon from 'assets/images/yellowOctagon.png';
import { getVitalStatusOutOfRangeText } from 'containers/PatientDashboard/PatientDashboardUtil';

const RATE_RANGE = 10;
const CONTROL_RANGE = 20;

export const getYMinValue = (vitalType: PersonVitalType, minRate: number = -10, minControlRate: number = -10): number => {
  //undefined will be transformed to a negative value and then the max will be taken
  let value: number;

  switch (vitalType) {
    case PersonVitalType.HEART_RATE: {
      value = Math.min(minRate - RATE_RANGE, minControlRate - CONTROL_RANGE, 30);
      break;
    }
    case PersonVitalType.BREATHING_RATE:
    default: {
      value = Math.min(minRate - RATE_RANGE, minControlRate - CONTROL_RANGE, 0);
    }
  }

  return value < 0 ? 0 : Math.trunc(value);
};

export const getYMaxValue = (vitalType: PersonVitalType, maxRate: number = 0, maxControlRate: number = 0): number => {
  let value: number;

  switch (vitalType) {
    case PersonVitalType.BREATHING_RATE: {
      value = Math.max(maxRate + RATE_RANGE, maxControlRate + CONTROL_RANGE, 60);
      break;
    }
    case PersonVitalType.HEART_RATE:
    default: {
      value = Math.max(maxRate + RATE_RANGE, maxControlRate + CONTROL_RANGE, 140);
    }
  }

  return Math.trunc(value);
};

export const getChartColor = (vitalType?: PersonVitalType) => {
  switch (vitalType) {
    case PersonVitalType.HEART_RATE:
      return Color.PINK_600;
    case PersonVitalType.BREATHING_RATE:
    default:
      return Color.DARK_BLUE_500;
  }
};

export const getMinTime = (dateNow: Date, timeFrame?: PersonVitalTimeFrame) => {
  const now = moment(dateNow);
  switch (timeFrame) {
    case PersonVitalTimeFrame.SEVEN_DAYS:
      return now.subtract(7, 'days').toDate();
    case PersonVitalTimeFrame.THIRTY_DAYS:
      return now.subtract(30, 'days').toDate();
    case PersonVitalTimeFrame.TWELVE_MONTHS:
      return now.subtract(12, 'months').toDate();
    case PersonVitalTimeFrame.TWENTY_FOUR_HOURS:
    default:
      return now.subtract(1, 'days').toDate();
  }
};

export const generateChartTicks = (minValue: number, maxValue: number): number[] => {
  let result = [] as number[];
  const difference = maxValue - minValue;
  const stepSize = difference < 100 ? 10 : 20;

  for (let value = minValue; value < maxValue; value += stepSize) {
    result.push(Math.floor(value / 10) * 10);
  }

  return result;
};

export const updateControlLinesStyles = (minControlRate?: number, maxControlRate?: number): void => {
  let minControlRateIndex: number | undefined = undefined;
  let maxControlRateIndex: number | undefined = undefined;

  const titles = [...document.querySelectorAll('svg[aria-label="A chart."] text[text-anchor="end"]')];
  titles?.forEach((title, index) => {
    const titleText = title.innerHTML;
    if (+titleText === minControlRate) {
      minControlRateIndex = index;
      title.setAttribute('fill', Color.DARK_GREEN);
    }
    if (+titleText === maxControlRate) {
      maxControlRateIndex = index;
      title.setAttribute('fill', Color.DARK_GREEN);
    }
  });

  const lines = [...document.querySelectorAll('svg[aria-label="A chart."] g[clip-path] g rect[stroke-width="0"]')];
  lines.forEach((line, index) => {
    if (index === minControlRateIndex || index === maxControlRateIndex) {
      line.setAttribute('fill', Color.DARK_GREEN);
    }
  });
};

export const updateCandleStickStyles = (): void => {
  // the approach doesn't work because the candlesticks are rendered each time when showing or hiding a tooltip
  const candleSticks = [...document.querySelectorAll('svg[aria-label="A chart."] g[clip-path] g rect[stroke-width="2"]')];
  candleSticks.forEach((candleStick) => {
    candleStick.setAttribute('rx', '6');
  });
};

export const updateHeaderStyles = (): void => {
  const header = document.querySelector('svg[aria-label="A chart."] text[text-anchor="start"]');
  header?.setAttribute('x', '8');
  header?.setAttribute('y', '24');
};

export const createCustomTooltip = (start: Date, end: Date, min: number | string, max: number | string, timeFrame?: PersonVitalTimeFrame, status?: VitalSignsStatus) => {
  let dateTooltip = '';
  switch (timeFrame) {
    case PersonVitalTimeFrame.TWENTY_FOUR_HOURS:
      dateTooltip = `${moment(start).format('D MMM, hh:mm A')} - ${moment(end).format('hh:mm A')}`;
      break;
    case PersonVitalTimeFrame.SEVEN_DAYS: {
      dateTooltip = `${moment(start).format('ddd, D MMM')}`;
      break;
    }
    case PersonVitalTimeFrame.THIRTY_DAYS:
      dateTooltip = `${moment(start).format('D MMM YYYY')}`;
      break;
    case PersonVitalTimeFrame.TWELVE_MONTHS:
      dateTooltip = `${moment(start).format('MMM YYYY')}`;
      break;
    default:
      dateTooltip = `${moment(start).format('MMM YYYY')}`;
      break;
  }

  let statusTooltip = '';
  if (status === VitalSignsStatus.ABNORMAL || status === VitalSignsStatus.SUBOPTIMAL) {
    statusTooltip = `<div style="margin: 16px 0; padding: 0;  height: 20px">
      <image src="${status === VitalSignsStatus.ABNORMAL ? exclamationOctagon : yellowOctagon}" style="width: 20px; position: absolute" />
      <p style="padding: 0; margin: 0 0 0 28px; color:${status === VitalSignsStatus.ABNORMAL ? Color.RED_600 : Color.YELLOW_400}; font-size:14px; line-height: 20px;">${getVitalStatusOutOfRangeText(status)}</p>
    </div>`;
  }

  return `<div style="height: ${statusTooltip ? '166px' : '130px'}; padding: 24px; border-radius: 16px; white-space: nowrap;">
    <p style="padding: 0; margin: 0 0 4px;  color:${Color.GRAY}; font-size:14px; line-height: 20px;">Range</p>
    <p style="padding: 0; margin: 0; color:${Color.DARK_BLUE_NEW}; font-size:30px; line-height: 38px;">${min} - ${max} bpm</p>
    <p style="padding: 0; margin: 0; color:${Color.GRAY}; font-size:14px; line-height: 20px;">${dateTooltip}</p>
    ${statusTooltip}
    </div>`;
};
