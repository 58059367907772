import React from 'react';
import { SVGBasicType } from 'types/global';
import { Color } from 'utils/color';

export const LogoMarkIcon = ({ width = 41, height = 40, color = Color.GRAY_BLUE, backgroundColor = Color.WHITE }: SVGBasicType) => {
  return (
    <svg width={width} height={height} viewBox="0 0 41 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g filter="url(#filter0_dd_5660_9466)">
        <g clipPath="url(#clip0_5660_9466)">
          <rect x="4" y="3" width="33" height="32" rx="6" fill="white" />
          <path
            d="M27.9405 25.7759C28.5268 25.7759 28.9999 26.2702 28.9999 26.888C28.9999 27.5058 28.5268 28.0001 27.9405 28.0001H19.4342C18.8479 28.0001 18.3748 27.5058 18.3748 26.888C18.3748 26.2702 18.8479 25.7759 19.4342 25.7759H27.9405Z"
            fill={color}
          />
          <path
            d="M19.4342 10C18.8479 10 18.3748 10.4943 18.3748 11.1121C18.3748 11.73 18.8479 12.2242 19.4342 12.2242H27.9405C28.5268 12.2242 28.9999 11.73 28.9999 11.1121C28.9999 10.4943 28.5268 10 27.9405 10H19.4342Z"
            fill={color}
          />
          <path
            d="M12.0594 17.7539C11.4731 17.7539 11 18.2482 11 18.866C11 19.4839 11.4731 19.9781 12.0594 19.9781H20.5657C21.152 19.9781 21.6252 19.4839 21.6252 18.866C21.6252 18.2482 21.152 17.7539 20.5657 17.7539H12.0594Z"
            fill={color}
          />
          <path
            d="M15.1454 21.8318C14.5591 21.8318 14.0859 22.3261 14.0859 22.9439C14.0859 23.5618 14.5591 24.056 15.1454 24.056H23.6517C24.238 24.056 24.7111 23.5618 24.7111 22.9439C24.7111 22.3261 24.238 21.8318 23.6517 21.8318H15.1454Z"
            fill={color}
          />
          <path
            d="M15.1454 13.8513C14.5591 13.8513 14.0859 14.3456 14.0859 14.9634C14.0859 15.5813 14.5591 16.0756 15.1454 16.0756H23.6517C24.238 16.0756 24.7111 15.5813 24.7111 14.9634C24.7111 14.3456 24.238 13.8513 23.6517 13.8513H15.1454Z"
            fill={color}
          />
        </g>
      </g>
      <defs>
        <filter id="filter0_dd_5660_9466" x="0" y="0" width="41" height="40" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
          <feOffset dy="0.5" />
          <feGaussianBlur stdDeviation="0.25" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_5660_9466" />
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
          <feOffset dy="1" />
          <feGaussianBlur stdDeviation="2" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0" />
          <feBlend mode="normal" in2="effect1_dropShadow_5660_9466" result="effect2_dropShadow_5660_9466" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect2_dropShadow_5660_9466" result="shape" />
        </filter>
        <clipPath id="clip0_5660_9466">
          <rect x="4" y="3" width="33" height="32" rx="6" fill={backgroundColor} />
        </clipPath>
      </defs>
    </svg>
  );
};
