import React from 'react';
import { SVGBasicType } from 'types/global';
import { Color } from 'utils/color';

export const WarningIcon = ({ width = 24, height = 24, color = Color.YELLOW_400 }: SVGBasicType) => {
  return (
    <svg width={width} height={height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M21.3103 7.52156L16.4784 2.68969C16.1963 2.40924 15.815 2.25126 15.4172 2.25H8.58281C8.185 2.25126 7.80371 2.40924 7.52156 2.68969L2.68969 7.52156C2.40924 7.80371 2.25126 8.185 2.25 8.58281V15.4172C2.25126 15.815 2.40924 16.1963 2.68969 16.4784L7.52156 21.3103C7.80371 21.5908 8.185 21.7487 8.58281 21.75H15.4172C15.815 21.7487 16.1963 21.5908 16.4784 21.3103L21.3103 16.4784C21.5908 16.1963 21.7487 15.815 21.75 15.4172V8.58281C21.7487 8.185 21.5908 7.80371 21.3103 7.52156Z"
        fill={color}
      />
    </svg>
  );
};
