import React, { useEffect } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { compose } from 'redux';
import { PermissionRole } from 'redux/person/personTypes';
import { keys } from 'ts-transformer-keys';
import NavSideBarContentWrapper from '../../components/NavSideBarContentWrapper/NavSideBarContentWrapper';
import { getOrganizationByIdSelector } from '../../redux/organization/organizationSelectors';
import { getAllOrganizationsThunk } from '../../redux/organization/organizationThunks';
import {
  getPermissionRoleByIdSelector,
  getPermissionRoleTypeByIdSelector,
  getPersonByIdSelector,
  getPersonPermissionRoleByIdSelector,
  getPersonPermissionRoleIdsByPersonIdSelector,
  hasAuthUserAdminPermissionSelector
} from '../../redux/person/personSelector';
import { getAllPermissionRolesThunk, getPersonPermissionsByOrgIdThunk, putPersonPermissionRoleThunk, removePersonPermissionRoleThunk } from '../../redux/person/personThunks';
import { ReduxState } from '../../redux/types';
import style from './AdminPermissionRoles.scss';
import { Text } from '../../components/Typography/Text';

type PropsFromRedux = ConnectedProps<typeof connectRedux>;
type Props = PropsFromRedux & {};

const keysOfPermissionRole = keys<PermissionRole>();

const AdminPermissionRoles: React.FC<Props> = ({ getAllPermissionRoles, hasAuthUserAdminPermission, permissionRoleTypeById, permissionRoleById }) => {
  if (!hasAuthUserAdminPermission) {
    return null;
  }

  useEffect(() => {
    getAllPermissionRoles();
  }, []);

  const permissionRoleFieldExclusions = ['permissionRoleTypeId', 'id', 'created', 'modified', 'permissionRoleType', 'permissionRoleTypeId'];
  const permissionRoleKeys = keysOfPermissionRole.filter((permissionRoleKey) => !permissionRoleFieldExclusions.includes(permissionRoleKey));

  const roles = Object.values(permissionRoleById).filter((role) => !!role?.permissionRoleType?.name);

  return (
    <NavSideBarContentWrapper headerText="User Permissions">
      <div className={style.tableContainer}>
        <table className={style.table}>
          <thead>
            <tr>
              <th>
                <Text variant="md" weight={500}>
                  Permissions / Roles
                </Text>
              </th>
              {roles.map((role) => (
                <th key={role.id}>
                  <Text variant="md" weight={500}>
                    {role.permissionRoleType?.name}
                  </Text>
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {permissionRoleKeys.map((permissionRoleKey) => (
              <tr key={permissionRoleKey}>
                <th className={style.wrap}>
                  <Text variant="md" weight={500} className={style.textWrap}>
                    {permissionRoleKey}
                  </Text>
                </th>
                {roles.map((role) => (
                  <td key={role.id}>
                    <Text variant="sm">{role[permissionRoleKey] ? 'X' : ' '}</Text>
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </NavSideBarContentWrapper>
  );
};

const connectRedux = connect(
  (state: ReduxState) => ({
    organizationById: getOrganizationByIdSelector(state),
    personById: getPersonByIdSelector(state),
    hasAuthUserAdminPermission: hasAuthUserAdminPermissionSelector(state),
    personPermissionRoleIdsByPersonId: getPersonPermissionRoleIdsByPersonIdSelector(state),
    personPermissionRoleById: getPersonPermissionRoleByIdSelector(state),
    permissionRoleTypeById: getPermissionRoleTypeByIdSelector(state),
    permissionRoleById: getPermissionRoleByIdSelector(state)
  }),
  {
    getPersonPermissionsByOrgId: getPersonPermissionsByOrgIdThunk,
    getAllOrganizations: getAllOrganizationsThunk,
    removePersonPermissionRole: removePersonPermissionRoleThunk,
    putPersonPermissionRole: putPersonPermissionRoleThunk,
    getAllPermissionRoles: getAllPermissionRolesThunk
  }
);

export default compose(connectRedux)(AdminPermissionRoles);
