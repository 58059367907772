import { Modal } from '@material-ui/core';
import Link from '@material-ui/core/Link';
import React, { useEffect } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { compose } from 'redux';
import envelopeSimpleIcon from '../../assets/images/envelope-simple.png';
import { Button } from '../../components/Button/Button';
import NavSideBarContentWrapper from '../../components/NavSideBarContentWrapper/NavSideBarContentWrapper';
import { TextField } from '../../components/TextField';
import { Text } from '../../components/Typography/Text';
import { getOrganizationByIdSelector, isLoadingAddNewOrganizationSelector, isLoadingUpdateOrganizationSelector } from '../../redux/organization/organizationSelectors';
import { addNewOrganizationThunk, getAllOrganizationsThunk, updateOrganizationThunk } from '../../redux/organization/organizationThunks';
import { NewOrganizationRequest, Organization } from '../../redux/organization/organizationTypes';
import { hasAuthUserAdminPermissionSelector } from '../../redux/person/personSelector';
import { ReduxState } from '../../redux/types';
import { emailAlertMessage, isEmailValid } from '../../utils/util';
import style from './AdminOrganizations.scss';
import { AllOrganizationsTable } from 'containers/AdminOrganizations/components/AllOrganizationsTable';

type PropsFromRedux = ConnectedProps<typeof connectRedux>;
type Props = PropsFromRedux & {};

const AdminOrganizations: React.FC<Props> = ({
  isLoadingAddNewOrganization,
  addNewOrganization,
  hasAuthUserAdminPermission,
  organizationById,
  isLoadingUpdateOrganization,
  updateOrganization,
  getAllOrganizations
}) => {
  if (!hasAuthUserAdminPermission) {
    return null;
  }
  const [newOrganizationRequest, setNewOrganizationRequest] = React.useState<NewOrganizationRequest>({
    name: '',
    logoImageUrl: '',
    email: '',
    familyName: '',
    givenNames: ''
  });
  const [errors, setErrors] = React.useState({
    email: ''
  });
  const [successMessage, setSuccessMessage] = React.useState('');
  const [apiError, setApiError] = React.useState('');
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const [selectedOrganization, setSelectedOrganization] = React.useState<Nullable<Organization>>(null);

  useEffect(() => {
    getAllOrganizations();
  }, []);

  const isSubmitBtnDisabled =
    isLoadingAddNewOrganization ||
    !newOrganizationRequest.name ||
    !newOrganizationRequest.logoImageUrl ||
    !newOrganizationRequest.email ||
    !newOrganizationRequest.givenNames ||
    !newOrganizationRequest.familyName;

  const isSubmitUpdateBtnDisabled = isLoadingUpdateOrganization || !selectedOrganization || !selectedOrganization.name || !selectedOrganization.logoImageUrl;

  const handleNewOrgChange = (e) => {
    setSuccessMessage('');
    setApiError('');
    const { name, value } = e.target;
    setNewOrganizationRequest({
      ...newOrganizationRequest,
      [name]: value
    });
  };

  const handleUpdateOrgChange = (e) => {
    setSuccessMessage('');
    setApiError('');
    const { name, value } = e.target;
    if (selectedOrganization) {
      setSelectedOrganization({
        ...selectedOrganization,
        [name]: value
      });
    }
  };

  const validateForm = () => {
    const newErrors = {
      email: isEmailValid(newOrganizationRequest.email) ? '' : emailAlertMessage(newOrganizationRequest.email) || ''
    };

    setErrors(newErrors);

    return Object.values(newErrors).every((error) => error === '');
  };

  const handleFormSubmit = async (event) => {
    event.preventDefault();
    if (validateForm()) {
      try {
        setApiError('');
        const newOrg = await addNewOrganization(newOrganizationRequest);
        setSuccessMessage(`Organization ${newOrganizationRequest.name} has been succesfully created.`);
        setNewOrganizationRequest({
          name: '',
          logoImageUrl: '',
          email: '',
          givenNames: '',
          familyName: ''
        });
      } catch (err) {
        setApiError('Something went wrong. Please try again.');
      }
    }
  };

  const onSelectOrganization = (org: Organization) => {
    setSuccessMessage('');
    setApiError('');
    setSelectedOrganization(org);
    setIsModalOpen(true);
  };

  const onModalClose = () => {
    setSelectedOrganization(null);
    setIsModalOpen(false);
    setSuccessMessage('');
    setApiError('');
  };
  const handleUpdateFormSubmit = async (event) => {
    event.preventDefault();
    if (selectedOrganization) {
      try {
        setApiError('');
        await updateOrganization(selectedOrganization);
        setSuccessMessage(`Organization ${newOrganizationRequest.name} has been succesfully updated.`);
        onModalClose();
      } catch (err) {
        setApiError('Something went wrong. Please try again.');
      }
    }
  };
  return (
    <NavSideBarContentWrapper headerText="Create New Organization">
      <form onSubmit={handleFormSubmit} className={style.mainContainer}>
        <div className={style.subContainer}>
          {/* Org Name */}
          <div className={style.formRow}>
            <TextField
              name="name"
              value={newOrganizationRequest.name}
              onChange={handleNewOrgChange}
              label="Organization Name"
              placeholder="Organization Name"
              autoComplete="new-password"
            />
          </div>
          {/* Logo Image Url */}
          <div className={style.formRow}>
            <TextField
              name="logoImageUrl"
              value={newOrganizationRequest.logoImageUrl}
              onChange={handleNewOrgChange}
              label="Logo Image url"
              placeholder="Logo Image url"
              autoComplete="new-password"
            />
          </div>
          {/* EMAIL */}
          <div className={style.formRow}>
            <TextField
              name="email"
              value={newOrganizationRequest.email}
              onChange={handleNewOrgChange}
              label="Organization Admin Email"
              placeholder="Organization Admin Email"
              autoComplete="new-password"
              error={errors.email}
              leading={<img src={envelopeSimpleIcon} />}
            />
          </div>
          <div className={style.formRow}>
            {/* FIRST NAME */}
            <TextField
              name="givenNames"
              value={newOrganizationRequest.givenNames}
              onChange={handleNewOrgChange}
              label="Organization Admin First name"
              placeholder="Organization Admin First name"
              autoComplete="new-password"
            />
            {/* LAST NAME */}
            <TextField
              name="familyName"
              value={newOrganizationRequest.familyName}
              onChange={handleNewOrgChange}
              label="Organization Admin Last name"
              placeholder="Organization Admin Last name"
              autoComplete="new-password"
            />
          </div>
        </div>
        <Text variant="sm">
          {`Note for image upload url: upload to S3 bucket: full-sleep-public-assets/portal-logos and copy non-expiring HTTPS url. `}
          <Link target="_blank" href="https://docs.google.com/document/d/1IML3qV0Rsuu9Ay4GNqlkdGIfzSHROiTIkGw1pDcSKHw">
            See here for more details
          </Link>
        </Text>
        <Button type="submit" variant="contained" disabled={isSubmitBtnDisabled} className={style.button}>
          Add New Org
        </Button>
        {!!apiError && (
          <Text variant="sm" className={style.apiError}>
            {apiError}
          </Text>
        )}
        {!!successMessage && (
          <Text variant="sm" className={style.successMessage}>
            {successMessage}
          </Text>
        )}
      </form>
      <AllOrganizationsTable onEditPress={onSelectOrganization} dataList={Object.values(organizationById)} />
      <Modal open={isModalOpen} onClose={onModalClose}>
        <div className={style.modalContainer}>
          <Text variant="h5" className={style.modalHeader}>
            Edit Organization
          </Text>
          <form onSubmit={handleUpdateFormSubmit} className={style.modalForm}>
            <div className={style.subContainer}>
              {/* Org Name */}
              <div className={style.formRow}>
                <TextField name="name" value={selectedOrganization?.name} onChange={handleUpdateOrgChange} label="Name" placeholder="Name" autoComplete="new-password" />
              </div>
              {/* Logo Image Url */}
              <div className={style.formRow}>
                <TextField
                  name="logoImageUrl"
                  value={selectedOrganization?.logoImageUrl}
                  onChange={handleUpdateOrgChange}
                  label="Logo Image url"
                  placeholder="Logo Image url"
                  autoComplete="new-password"
                />
              </div>
              <Button type="submit" variant="contained" disabled={isSubmitUpdateBtnDisabled} className={style.button}>
                Update Organization
              </Button>
            </div>
          </form>
        </div>
      </Modal>
    </NavSideBarContentWrapper>
  );
};

const connectRedux = connect(
  (state: ReduxState) => ({
    organizationById: getOrganizationByIdSelector(state),
    isLoadingAddNewOrganization: isLoadingAddNewOrganizationSelector(state),
    isLoadingUpdateOrganization: isLoadingUpdateOrganizationSelector(state),
    hasAuthUserAdminPermission: hasAuthUserAdminPermissionSelector(state)
  }),
  {
    addNewOrganization: addNewOrganizationThunk,
    updateOrganization: updateOrganizationThunk,
    getAllOrganizations: getAllOrganizationsThunk
  }
);

export default compose(connectRedux)(AdminOrganizations);
