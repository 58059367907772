import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import React, { useCallback } from 'react';
import { Menu, MenuItem, MenuItemStyles, Sidebar } from 'react-pro-sidebar';
import { ConnectedProps, connect } from 'react-redux';
import { Link, Outlet } from 'react-router-dom';
import { compose } from 'redux';
import { Text } from '../../components/Typography';
import { NAV_BROKEN_ENABLED } from '../../navigation/NavSideBar/NavSideBar';
import { hasAuthUserAdminPermissionSelector, hasCreateOrgPermissionSelector } from '../../redux/person/personSelector';
import { ReduxState } from '../../redux/types';
import { Color } from '../../utils/color';
import { RouteNames } from '../../utils/navigationUtils';
import style from './AdminSideBar.scss';

const SIDEBAR_WIDTH = 300;

const menuItemStyles: MenuItemStyles = {
  root: {
    fontFamily: 'Outfit',
    fontSize: '16px',
    fontWeight: 400,
    color: Color.GRAY_BLUE
  },
  button: {
    transition: 'background-color 0.1s ease-out',
    padding: '8px 12px',
    margin: '0px 16px',
    borderRadius: '6px',
    gap: '12px',
    '&:hover': {
      backgroundColor: Color.WHITE,
      opacity: 0.5
    },
    '&.ps-active': {
      backgroundColor: Color.GRAY_50,
      color: Color.GRAY_BLUE,
      fontWeight: 400
    }
  },
  icon: {
    margin: '0px',
    padding: '0px',
    width: '20px',
    minWidth: '20px',
    height: '20px',
    lineHeight: '20px'
  }
};

type PropsFromRedux = ConnectedProps<typeof connectRedux>;

export const AdminSideBar: React.FC<PropsFromRedux> = ({ hasCreateOrgPermission, hasAuthUserAdminPermission }) => {
  const [toggled, setToggled] = React.useState<boolean>(false);
  const [broken, setBroken] = React.useState<boolean>(false);

  const closeSideBar = useCallback(() => {
    setToggled(false);
  }, []);

  return (
    <div className={style.container}>
      <Sidebar
        toggled={toggled}
        onBackdropClick={closeSideBar}
        onBreakPoint={setBroken}
        breakPoint={NAV_BROKEN_ENABLED ? 'xl' : undefined}
        backgroundColor={Color.WHITE}
        width={`${SIDEBAR_WIDTH}px`}
        rootStyles={{
          borderRightColor: Color.GRAY_100
        }}
      >
        <div className={style.sideBarContainer}>
          <Menu menuItemStyles={menuItemStyles}>
            <MenuItem component={<Link to={RouteNames.HOME} />} icon={<ArrowBackIcon fontSize="small" />}>
              Back
            </MenuItem>
          </Menu>

          <Text variant="h5" className={style.adminHeader}>
            Admin Settings
          </Text>

          <div className={style.topMenuContainer}>
            <Menu menuItemStyles={menuItemStyles}>
              {hasCreateOrgPermission && <MenuItem component={<Link to={RouteNames.ADMIN_ORGANIZATIONS} onClick={closeSideBar} />}>Manage Organizations</MenuItem>}
              {hasAuthUserAdminPermission && <MenuItem component={<Link to={RouteNames.ADMIN_USERS} onClick={closeSideBar} />}>Manage Users & Permissions</MenuItem>}
              {hasAuthUserAdminPermission && <MenuItem component={<Link to={RouteNames.ADMIN_PERMISSION_ROLES} onClick={closeSideBar} />}>Permission Roles</MenuItem>}
            </Menu>
          </div>
        </div>
      </Sidebar>
      <Outlet context={[broken, toggled, setToggled]} />
    </div>
  );
};

const connectRedux = connect((state: ReduxState) => ({
  hasCreateOrgPermission: hasCreateOrgPermissionSelector(state),
  hasAuthUserAdminPermission: hasAuthUserAdminPermissionSelector(state)
}));

export default compose(connectRedux)(AdminSideBar) as React.ComponentType;
