import { DateTime, Zone } from 'luxon';
import moment, { Moment } from 'moment-timezone';
import { pluralize } from './content';
import { isSameDay } from 'date-fns';

export const DEFAULT_TIMEZONE_ID = 'America/Los_Angeles';

export const formatTimestamp = (t: Nullable<string> | null) => {
  if (!t) return '?';
  return new Date(t).toLocaleString();
};

export const reverseFormatTimestamp = (t: string | null) => {
  if (!t || t == '?') return null;
  return new Date(t).toISOString();
};

export const roundTextTime = (time: Nullable<string>, includeSeconds: boolean, includeAmPm?: Nullable<boolean>): Nullable<string> => {
  if (!time || !time.includes(':') || time.indexOf(':') === time.lastIndexOf(':')) {
    return time;
  }
  return roundMomentTime(moment(time, 'HH:mm:ss'), includeSeconds, includeAmPm);
};

export const roundMomentTime = (time: Nullable<Moment>, includeSeconds: boolean, includeAmPm?: Nullable<boolean>): Nullable<string> => {
  if (!time) {
    return undefined;
  }
  const roundedTimeMoment: Moment = time.add(30, 'seconds').seconds(0);
  return roundedTimeMoment.format((!includeAmPm ? 'HH:mm' : 'hh:mm') + (includeSeconds ? ':ss' : '') + (includeAmPm ? ' A' : ''));
};

export const msToMin = (num: Nullable<number>): string => {
  if (!num) {
    return '' + num;
  }
  return (num / 60000).toFixed(0);
};

export const msToHr = (num: Nullable<number>): string => {
  if (!num) {
    return '' + num;
  }
  return (num / 3600000).toFixed(2);
};

export const isMinutesAgo = (minutes: number, fromTimestamp?: Nullable<number>) => {
  const MINUTES_MS = 1000 * 60 * minutes;
  const nowMs: number = +new Date();
  return !fromTimestamp || nowMs - fromTimestamp >= MINUTES_MS;
};

export const timeOfDayInMinutes = (moment?: Nullable<Moment>): Nullable<number> => {
  return !moment ? undefined : moment.minutes() + moment.hours() * 60;
};

export const tsAsDateTimeStr = (ts?: Nullable<string>, zone?: Nullable<string>): string => {
  return tsToText(ts, zone, 'YYYY-MM-DD hh:mm:ss A z');
};

export const tsAsDateStr = (ts?: Nullable<string>, zone?: Nullable<string>): string => {
  return tsToText(ts, zone, 'YYYY-MM-DD');
};

export const tsAsNightDateStr = (ts: string, tz: Nullable<string>, format?: string): Nullable<string> => {
  return momentToText(tsAsNightDateMoment(ts, tz), format || 'YYYY-MM-DD');
};

export const tsAsNightDateMoment = (ts: string, tz: Nullable<string>): Nullable<Moment> => {
  return asNightDateMoment(textToMoment(ts, tz));
};

export const asNightDateMoment = (moment?: Nullable<Moment>): Nullable<Moment> => {
  if (moment) {
    const morningOfDay: Moment = moment.clone().startOf('day').add(7, 'hours');
    if (!moment.isAfter(morningOfDay)) {
      return moment.clone().subtract(1, 'days');
    }
  }
  return moment;
};

export const overrideDate = (moment?: Nullable<Moment>, override?: Nullable<Moment>): Nullable<Moment> => {
  return moment?.clone()?.set({ y: override?.year(), M: override?.month(), d: override?.day(), D: override?.date() });
};

export const adjustToWithinDayOfAnchor = (moment?: Nullable<Moment>, anchor?: Nullable<Moment>): Nullable<Moment> => {
  let adjustDown: Nullable<Moment> = moment?.clone();
  while (adjustDown && adjustDown.diff(anchor, 'd') >= 1) {
    adjustDown = adjustDaysDown(adjustDown);
  }
  const adjustDownOver: Nullable<Moment> = adjustDaysDown(adjustDown);
  adjustDown = absDiff(adjustDown, anchor) > absDiff(adjustDownOver, anchor) ? adjustDownOver : adjustDown;

  let adjustUp: Nullable<Moment> = moment?.clone();
  while (adjustUp && adjustUp.diff(anchor, 'd') <= -1) {
    adjustUp = adjustDaysUp(adjustUp);
  }
  const adjustUpOver: Nullable<Moment> = adjustDaysUp(adjustUp);
  adjustUp = absDiff(adjustUp, anchor) > absDiff(adjustUpOver, anchor) ? adjustUpOver : adjustUp;
  return absDiff(adjustDown, anchor) > absDiff(adjustUp, anchor) ? adjustUp : adjustDown;
};

const adjustDaysUp = (moment?: Nullable<Moment>): Nullable<Moment> => {
  return incrementDays(moment, 1);
};

const adjustDaysDown = (moment?: Nullable<Moment>): Nullable<Moment> => {
  return incrementDays(moment, -1);
};

const absDiff = (moment1?: Nullable<Moment>, moment2?: Nullable<Moment>): number => {
  return Math.abs(moment1?.diff(moment2) || 0);
};

const incrementDays = (moment?: Nullable<Moment>, days?: Nullable<number>): Nullable<Moment> => {
  if (days || days === 0) {
    return moment?.clone().set({ d: moment?.days() + days });
  }
  return moment;
};

export const tsAsTimeStr = (ts?: Nullable<string>, zone?: Nullable<string>): string => {
  return tsToText(ts, zone, 'h:mm A');
};

export const tsAsDetailedDateTimeStr = (ts?: Nullable<string>, zone?: Nullable<string>): string => {
  const tsMoment: Nullable<Moment> = textToMoment(ts, zone);
  if (!tsMoment) {
    return '';
  }
  const diff: string = tsAsTimeSince(tsMoment);
  const formatted: string = tsMoment.format('dddd, MMMM Do, h:mm:ss A z');
  return formatted + ' (' + diff + ')';
};

export const tsTextAsTimeSince = (ts?: Nullable<string>, zone?: Nullable<string>): string => {
  const tsMoment: Nullable<Moment> = textToMoment(ts, zone);
  if (!tsMoment) {
    return '';
  }
  return tsAsTimeSince(tsMoment);
};

export const tsAsTimeSince = (tsMoment?: Nullable<Moment>): string => {
  if (!tsMoment) {
    return '';
  }
  const currentMoment: Moment = moment();
  const duration: moment.Duration = moment.duration(currentMoment.diff(tsMoment));
  const days: number = Math.floor(duration.asDays());
  duration.subtract(moment.duration(days, 'days'));
  const hours: number = duration.hours();
  return pluralize(days, 'day') + ' ' + pluralize(hours, 'hour') + ' ago';
};

export const tsAsTimeSinceUnit = (ts?: Nullable<string>, zone?: Nullable<string>): string => {
  const tsMoment: Nullable<Moment> = textToMoment(ts, zone);
  if (!tsMoment) {
    return '';
  }
  const currentMoment: Moment = moment();
  const duration: moment.Duration = currentMoment.isBefore(tsMoment) ? moment.duration(tsMoment.diff(currentMoment)) : moment.duration(currentMoment.diff(tsMoment));
  const days: number = Math.floor(duration.asDays());
  if (days > 0) {
    return `${Math.floor(days)} d`;
  }
  const hours: number = duration.hours();
  if (hours > 0) {
    return `${Math.floor(hours)} hrs`;
  }
  const minutes: number = duration.minutes();
  return `${Math.floor(minutes)} min`;
};

export const timeAsTimeStr = (time?: Nullable<string>, tz?: Nullable<string>): string => {
  const adjustedTs: Nullable<Moment> = textToMoment(time, tz, 'HH:mm:ss');
  return !adjustedTs ? '' : adjustedTs.format('hh:mm A');
};

export const textToMoment = (dateTimeText?: Nullable<string>, timeZone?: Nullable<string>, dateFormat?: Nullable<string>): Nullable<Moment> => {
  if (!dateTimeText) {
    return undefined;
  }
  return timeZone
    ? dateFormat
      ? moment.tz(dateTimeText, dateFormat, timeZone)
      : moment.tz(dateTimeText, timeZone)
    : dateFormat
      ? moment(dateTimeText, dateFormat)
      : moment(dateTimeText);
};

export const currentDateTime = (timeZone?: Nullable<string>): Moment => (timeZone ? moment().tz(timeZone) : moment());

const momentToText = (moment?: Nullable<Moment>, format?: Nullable<string>): string => {
  if (!moment || !format) {
    return '';
  }
  return moment.format(format);
};

export const tsToText = (ts?: Nullable<string>, zone?: Nullable<string>, format?: Nullable<string>): string => {
  const moment: Nullable<Moment> = textToMoment(ts, zone);
  return momentToText(moment, format);
};

export const isTsInRange = (ts: Nullable<DateTime>, startTs: DateTime, endTs): boolean => {
  return !!ts && ts >= startTs && ts <= endTs;
};

export const isoStringToShortDateTimeString = (ts?: Nullable<string>, zone?: Nullable<string>): string => {
  if (ts && zone) {
    return DateTime.fromISO(ts, { zone: zone }).toFormat('LL/dd HH:mm:ss');
  }
  return '';
};

export const isoStringToShortDateTimeStringIncludingMs = (ts?: Nullable<string>, zone?: Nullable<string>): string => {
  if (ts && zone) {
    return DateTime.fromISO(ts, { zone: zone }).toFormat('LL/dd HH:mm:ss.ms');
  }
  return '';
};

export const mSToShortDateTimeString = (ms?: Nullable<number>, zone?: Nullable<string>): string => {
  if (ms && zone) {
    return DateTime.fromMillis(ms).setZone(zone).toFormat('LL/dd HH:mm:ss');
  }
  return '';
};

export const timestampToLocaleDateString = (timestamp: string, zone?: Nullable<string>): string => {
  const date = zone ? moment.tz(timestamp, zone) : moment(timestamp);
  const today = zone ? moment().tz(zone).startOf('day') : moment().startOf('day');
  const dayDifference = date.diff(today, 'days');
  let formattedDate = '';
  if (dayDifference === 0) {
    formattedDate = `Today, ${date.format('ddd D MMM')}`;
  } else {
    formattedDate = `${date.format('dddd, ddd D MMM')}`;
  }

  return formattedDate;
};

export const getTimesInFiveMinuteInterval = (addNull?: boolean): Nullable<string>[] => {
  const times: Nullable<string>[] = [];
  if (addNull) {
    times.push(null);
  }
  let time: Moment = moment().startOf('day');
  for (let i = 0; i < 24 * 12; i++) {
    times.push(time.format('hh:mm A'));
    time = time.add(5, 'minutes');
  }
  return times;
};

export const getTimeValueFromSelectField = (time: string): string => {
  const timeParts: string[] = time.split(' ');
  let timeValue: string = timeParts[0];
  const amPm: string = timeParts[1];
  const timeValueParts: string[] = timeValue.split(':');
  const hour: number = parseInt(timeValueParts[0]);
  const minute: number = parseInt(timeValueParts[1]);
  if (amPm === 'PM' && hour < 12) {
    const hourValue: number = hour + 12;
    const hourString = hourValue < 10 ? '0' + hourValue.toString() : hourValue.toString();
    const minuteString = minute < 10 ? '0' + minute.toString() : minute.toString();
    return hourString + ':' + minuteString + ':00';
  } else if (amPm === 'AM' && timeValue.startsWith('12:')) {
    timeValue = '00:' + timeValueParts[1];
  }
  return timeValue + ':00';
};

export const isBefore = (date: Nullable<string>): boolean => {
  return !!date && DateTime.fromISO(date).toMillis() < DateTime.local().toMillis();
};

export function getCarePlanDateUTC(isoDate: string, timeZone: Nullable<string>): DateTime {
  const dateLocalObject = DateTime.fromISO(isoDate, timeZone ? { zone: timeZone } : {});
  const dateMidnightLocalObject = dateLocalObject.startOf('day'); // 3/23 00:00 in local time
  return DateTime.utc(dateMidnightLocalObject.get('year'), dateMidnightLocalObject.get('month'), dateMidnightLocalObject.get('day'), 0, 0, 0, 0); // 3/23 00:00 in utc
}

export const isDateToday = (date: Date | null) => {
  if (!date) return false;

  const today = new Date();
  return isSameDay(today, date); // Using date-fns to compare dates
};

export function isTodayCarePlanDate(carePlanDateUTCMs: number, timeZone: string) {
  const todayMidnightLocal = DateTime.now().setZone(timeZone).startOf('day');
  const todayMidnightUTC = DateTime.utc(todayMidnightLocal.get('year'), todayMidnightLocal.get('month'), todayMidnightLocal.get('day'), 0, 0, 0, 0); // 3/23 00:00 in utc

  return todayMidnightUTC.toMillis() <= carePlanDateUTCMs;
}

export function isWithin24HrsCarePlanDate(carePlanDateUTCMs: number) {
  const nowUTC = new Date().getTime();

  return nowUTC <= carePlanDateUTCMs;
}

export function getCarePlanDateUTCFromBedTimeTs(currentBedTimeString: string, timeZone: string): DateTime {
  const bedTimeObject = DateTime.fromISO(currentBedTimeString, { zone: timeZone }); // bed time Tuesday 3/23 22:00
  const isMorningDate = bedTimeObject.get('hour') <= 7;
  const nightDate = isMorningDate ? bedTimeObject.startOf('day') : bedTimeObject.startOf('day').plus({ day: 1 }); // adding one day to match care plan date
  return DateTime.utc(nightDate.get('year'), nightDate.get('month'), nightDate.get('day'), 0, 0, 0, 0); // 3/23 00:00 in utc
}

export const getStringFromUTCMs = (dateUTCMs: number, format: string): string => {
  const dateTimeUtc = DateTime.fromMillis(dateUTCMs, { zone: 'utc' });
  return dateTimeUtc.toFormat(format);
};

/**
 * Converts a UTC ISO timestamp to a localized DateTime object with zero offset.
 *
 * @param {string} utcTimestamp - The ISO timestamp in UTC.
 * @param {Nullable<string>} timezone - The target timezone (e.g., "America/New_York").
 * @returns {DateTime} A localized DateTime object in the specified timezone or the current system timezone.
 */
export const convertUTCToLocalizedDateTime = (utcTimestamp: string, timezone: Nullable<string>): DateTime => {
  const utcDateTime = DateTime.fromISO(utcTimestamp, { zone: 'utc' });
  const currentTimeZone = timezone || DateTime.now().zoneName;

  return utcDateTime.setZone(currentTimeZone);
};

/**
 * Converts a timestamp in milliseconds to a UTC DateTime object with zero offset.
 *
 * @param {number} timestampMs - The timestamp in milliseconds.
 * @param {Nullable<string>} timezone - The target timezone (e.g., "America/New_York").
 * @returns {DateTime} A UTC DateTime object adjusted to zero offset.
 */
export const convertMillisToUTC = (timestampMs: number, timezone: Nullable<string>): DateTime => {
  const localDateTime = DateTime.fromMillis(timestampMs, { zone: timezone || 'local' });
  return localDateTime.toUTC();
};

export const getStartOfToday = (timezone?: string): string => {
  const now = timezone ? DateTime.now().setZone(timezone) : DateTime.now();
  return now.startOf('day').toISO()!;
};

export const getStartOfTodayDate = (timezone?: string): Date => {
  const now = timezone ? DateTime.now().setZone(timezone) : DateTime.now();
  return now.startOf('day').toJSDate();
};

export const getStartOfTomorrowDate = (timezone?: string): Date => {
  const now = timezone ? DateTime.now().setZone(timezone) : DateTime.now();
  return now.startOf('day').plus({ day: 1 }).toJSDate();
};

export const getStartOfNextWeekDate = (timezone?: string): Date => {
  const now = timezone ? DateTime.now().setZone(timezone) : DateTime.now();
  return now.startOf('day').plus({ day: 7 }).toJSDate();
};

export const incrementDay = (date: string): string => {
  const dateTime = DateTime.fromISO(date);
  return dateTime.plus({ day: 1 }).toISO()!;
};

export const decrementDay = (date: string): string => {
  const dateTime = DateTime.fromISO(date);
  return dateTime.minus({ day: 1 }).toISO()!;
};

export const formatDateWithLuxon = (date = null, timeZone = 'America/Los_Angeles') => {
  const dateTime = date ? DateTime.fromJSDate(date).setZone(timeZone) : DateTime.now().setZone(timeZone);

  return dateTime.toFormat('hh:mm a (ZZZZ)');
};

export const getNowRoundedTo30Minutes = (timezone: string) => {
  const timeInTargetZone = DateTime.now().setZone(timezone);

  const currentMinutes = timeInTargetZone.minute;
  const minutesToAdd = currentMinutes < 30 ? 30 - currentMinutes : 60 - currentMinutes;

  let roundedTime = timeInTargetZone.plus({ minutes: minutesToAdd }).startOf('minute');

  if (roundedTime < timeInTargetZone) {
    roundedTime = roundedTime.plus({ minutes: 30 });
  }

  return new Date(timeInTargetZone.year, timeInTargetZone.month - 1, timeInTargetZone.day, timeInTargetZone.hour, timeInTargetZone.minute, timeInTargetZone.second);
};

export const getElapsedTimeDisplay = (isoTime: string, minutesCap: number = 59, hoursCap = 23) => {
  const now = moment();
  const date = moment(isoTime);
  const diffMinutes = now.diff(date, 'minutes');
  const diffHours = now.diff(date, 'hours');
  if (diffMinutes <= minutesCap) {
    return `${diffMinutes}m ago`;
  }
  if (diffHours <= hoursCap) {
    return `${diffHours}h ago`;
  }
  return date.format('MM/DD/YYYY h:mm a');
};

export const getDateFromStringIgnoreTimezone = (dateStr: string) => {
  // "2025-02-09T00:00:00.000Z" "2025-02-09 16:00:14"
  if (!dateStr || dateStr.length < 19) return null;
  return new Date(
    Number(dateStr.substring(0, 4)),
    Number(dateStr.substring(5, 7)) - 1,
    Number(dateStr.substring(8, 10)),
    Number(dateStr.substring(11, 13)),
    Number(dateStr.substring(14, 16)),
    Number(dateStr.substring(17, 19))
  );
};
