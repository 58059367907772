import React, { useEffect, useState } from 'react';
import { ConnectedProps, connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { compose } from 'redux';
import plusIcon from '../../assets/images/plus.png';
import { BreadCrumbData } from '../../redux/common/types';
import { appendBreadcrumbAction } from '../../redux/oauth/oauthActions';
import { getPersonByIdSelector, getPersonVitalsByPersonIdSelector, hasPatientCreatePermissionSelector } from '../../redux/person/personSelector';
import { ReduxState } from '../../redux/types';
import style from './AllPatients.scss';
import PatientTable from './components/PatientTable/PatientTable';
import { PatientTableData } from './components/PatientTable/PatientTableTypes';
import { getPersonByIdThunk, getPersonVitalsThunk, setSelectedPatientIdThunk } from '../../redux/person/personThunks';
import { getAuthPersonSelector } from '../../redux/oauth/oauthSelectors';
import { PatientInfoDrawer } from 'components/PatientInfoDrawer/PatientInfoDrawer';
import NoPatients from './components/NoPatients';
import NavTopBarContentWrapper from 'components/NavTopBarContentWrapper/NavTopBarContentWrapper';
import { Button } from 'components/Button/Button';
import { Text } from 'components/Typography';
import { getDevicePersonOverviewThunk } from '../../redux/device/deviceThunks';

const FETCH_PATIENT_STAT_INTERVAL = 30000;

type PropsFromRedux = ConnectedProps<typeof connectRedux>;

type Props = PropsFromRedux & {};

const AllPatients: React.FC<Props> = ({
  hasPatientCreatePermission,
  personVitalsByPersonIdSelector,
  authPersonSelector,
  personByIdSelector,
  appendBreadCrumbs,
  getPersonVitals,
  setSelectedPatientIdThunkCallback,
  fetchPersonById,
  fetchDevicePersonOverview
}) => {
  const [fetchCount, setFetchCount] = useState(0);
  const [patientTableData, setPatientTableData] = useState<PatientTableData[]>([]);
  const [selectedPatientId, setSelectedPatientId] = useState<string>('');
  const organizationId = authPersonSelector?.organizationId ?? '';
  const [initDone, setInitDone] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    const fetchMeasurements = async () => {
      await getPersonVitals(organizationId);
      setInitDone(true);
    };

    fetchMeasurements();
  }, [fetchCount]);

  useEffect(() => {
    let cnt = 0;
    const intervalId = setInterval(() => {
      setFetchCount(++cnt);
    }, FETCH_PATIENT_STAT_INTERVAL);

    fetchDevicePersonOverview();

    return () => {
      clearInterval(intervalId);
    };
  }, []);

  useEffect(() => {
    const getPatientTableData = (): PatientTableData[] => {
      const tableData: PatientTableData[] = [];
      for (const personId of Object.keys(personVitalsByPersonIdSelector)) {
        const personVitals = personVitalsByPersonIdSelector[personId];
        if (!personVitals) {
          continue;
        }

        const tableRow: PatientTableData = {
          ...personVitals,
          name: personVitals.personName ?? '',
          avatar: ''
        };
        tableData.push(tableRow);
      }

      return tableData;
    };

    setPatientTableData(getPatientTableData());
  }, [personVitalsByPersonIdSelector]);

  const unselectPatientId = React.useCallback(() => {
    setSelectedPatientId('');
  }, []);

  const goToPatientDashboard = React.useCallback((personId: string) => {
    fetchPersonById(personId);
    setSelectedPatientId(personId);
    setSelectedPatientIdThunkCallback(personId);
  }, []);

  const goToNewPatientPage = React.useCallback(
    (e) => {
      navigate('/patients/newPatient');
    },
    [navigate]
  );

  return (
    <NavTopBarContentWrapper
      headerText={() => {
        return (
          <div className={style.headerContainer}>
            <Text variant="h5">Patients</Text>
            {initDone && hasPatientCreatePermission && patientTableData.length > 0 && (
              <Button className={style.newPatient} variant="contained" onClick={goToNewPatientPage} startIcon={<img src={plusIcon} className={style.plusIcon} />}>
                Create new
              </Button>
            )}
          </div>
        );
      }}
    >
      {initDone && patientTableData.length > 0 && <PatientTable patientTableData={patientTableData} onPressTableRow={goToPatientDashboard} />}
      {initDone && patientTableData.length <= 0 && <NoPatients />}
      <PatientInfoDrawer person={personByIdSelector[selectedPatientId]} visible={!!selectedPatientId} onClose={unselectPatientId} withButtons />
    </NavTopBarContentWrapper>
  );
};

const connectRedux = connect(
  (state: ReduxState) => ({
    hasPatientCreatePermission: hasPatientCreatePermissionSelector(state),
    authPersonSelector: getAuthPersonSelector(state),
    personVitalsByPersonIdSelector: getPersonVitalsByPersonIdSelector(state),
    personByIdSelector: getPersonByIdSelector(state)
  }),
  {
    appendBreadCrumbs: (breadCrumbData: BreadCrumbData, defaultCrumbHistory: BreadCrumbData[], isOldApp?: boolean) =>
      appendBreadcrumbAction({ breadCrumbData, defaultCrumbHistory, isOldApp }),
    getPersonVitals: getPersonVitalsThunk,
    setSelectedPatientIdThunkCallback: setSelectedPatientIdThunk,
    fetchPersonById: getPersonByIdThunk,
    fetchDevicePersonOverview: getDevicePersonOverviewThunk
  }
);

export default compose(connectRedux)(AllPatients);
