import React, { useEffect } from 'react';
import style from './Insights.scss';
import { Text } from '../../../../components/Typography';
import { Button, Grid } from '@mui/material';
import { EnumSelect } from '../../../../components/EnumSelect/EnumSelect';
import classNames from 'classnames';
import { GridRow } from './GridRow';
import { InsightsChart } from './InsightsChart';
import { connect, ConnectedProps } from 'react-redux';
import { ReduxState } from '../../../../redux/types';
import { compose } from 'redux';
import { getSelectedPersonVitalInsights } from '../../../../redux/person/personSelector';
import { getPersonVitalInsightsThunk } from '../../../../redux/person/personThunks';
import { PersonVitalTimeFrame, PersonVitalType } from '../../../../redux/person/personTypes';
import { getFixedPointDisplay } from '../../../../utils/util';

type PropsFromRedux = ConnectedProps<typeof connectRedux>;
type Props = PropsFromRedux & {
  personId: string;
  timeZoneId: string;
};

const Insights: React.FC<Props> = ({ personId, timeZoneId, selectedPersonVitalInsights, getPersonVitalInsights }) => {
  const [vitalType, setVitalType] = React.useState<PersonVitalType>(PersonVitalType.HEART_RATE);
  const [timeFrame, setTimeFrame] = React.useState<PersonVitalTimeFrame>(PersonVitalTimeFrame.TWENTY_FOUR_HOURS);
  const { patientMetrics, controlLevel } = selectedPersonVitalInsights ?? {};

  const onSelectionChange = (selected) => {
    setVitalType(selected);
  };

  useEffect(() => {
    getPersonVitalInsights(personId, vitalType, timeFrame);
  }, [vitalType, timeFrame]);

  return (
    <div className={style.topContainer}>
      <div className={style.leftContainer}>
        <div className={style.insightsTextContainer}>
          <Text variant="xl">Insights</Text>
        </div>
        <div>
          <Text variant="sm" className={style.defaultGrayText}>
            Select related data
          </Text>
          <EnumSelect enumData={PersonVitalType} onSelectionChange={onSelectionChange} initialSelection={PersonVitalType.HEART_RATE} />
          <div className={style.metricsContainer}>
            <Grid container>
              <GridRow label="" metricValue="Patient metrics" normalControlValue="Control level" suboptimalControlValue="Suboptimal" abnormalControlValue="Abnormal" />
              <GridRow
                label="Min (bpm)"
                metricValue={getFixedPointDisplay(patientMetrics?.minRate)}
                normalControlValue={getFixedPointDisplay(controlLevel?.normalMinRate)}
                abnormalControlValue={getFixedPointDisplay(controlLevel?.abnormalMinRate)}
                suboptimalControlValue={getFixedPointDisplay(controlLevel?.subnormalMinRate)}
                rowValueTextClassName={style.metricsValueText}
                metricStatus={patientMetrics?.minRateStatus}
              />
              <GridRow
                label="Max (bpm)"
                metricValue={getFixedPointDisplay(patientMetrics?.maxRate)}
                normalControlValue={getFixedPointDisplay(controlLevel?.normalMaxRate)}
                abnormalControlValue={getFixedPointDisplay(controlLevel?.abnormalMaxRate)}
                suboptimalControlValue={getFixedPointDisplay(controlLevel?.subnormalMaxRate)}
                rowValueTextClassName={style.metricsValueText}
                metricStatus={patientMetrics?.maxRateStatus}
              />
              <GridRow
                label="Avg (bpm)"
                metricValue={getFixedPointDisplay(patientMetrics?.avgRate)}
                normalControlValue="-"
                suboptimalControlValue="-"
                abnormalControlValue="-"
                rowValueTextClassName={style.metricsValueText}
              />
            </Grid>
          </div>
        </div>
      </div>

      <div className={style.rightContainer}>
        <div className={style.durationHeader}>
          {Object.keys(PersonVitalTimeFrame).map((key, index) => {
            return (
              <Button
                key={index}
                className={classNames(style.tab, { [style.active]: timeFrame === PersonVitalTimeFrame[key] })}
                onClick={() => setTimeFrame(PersonVitalTimeFrame[key])}
              >
                {PersonVitalTimeFrame[key]}
              </Button>
            );
          })}
        </div>
        <InsightsChart
          timeZoneId={timeZoneId}
          personVitalInsights={selectedPersonVitalInsights}
          vitalType={vitalType}
          timeFrame={timeFrame}
          minRate={patientMetrics?.minRate}
          maxRate={patientMetrics?.maxRate}
          minControlRate={controlLevel?.normalMinRate}
          maxControlRate={controlLevel?.normalMaxRate}
        />
      </div>
    </div>
  );
};

const connectRedux = connect(
  (state: ReduxState) => ({
    selectedPersonVitalInsights: getSelectedPersonVitalInsights(state)
  }),
  {
    getPersonVitalInsights: getPersonVitalInsightsThunk
  }
);

export default compose(connectRedux)(Insights);
