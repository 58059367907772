import React, { useEffect, useState } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { ConfirmationDialogV2 } from '../../../../components/ConfirmationDialog/ConfirmationDialogV2';
import { ReduxState } from '../../../../redux/types';
import { deletePersonThunk } from '../../../../redux/person/personThunks';
import { compose } from 'redux';
import { Text } from '../../../../components/Typography';
import style from './DeletePatient.scss';

export type DeletePatientProps = {
  patientName: string;
  patientId: string;
  showDialog: boolean;
  onDialogClose: () => void;
};

type PropsFromRedux = ConnectedProps<typeof connectRedux>;
type Props = PropsFromRedux & DeletePatientProps;

const DeletePatient: React.FC<Props> = ({ patientName, patientId, deletePatient, showDialog, onDialogClose }) => {
  const [showDeletePatientConfirmation, setShowDeletePatientConfirmation] = React.useState(showDialog);
  const [successMsg, setSuccessMsg] = useState('');
  const [errMsg, setErrMsg] = useState('');

  const onDeletePatientConfirmation = async () => {
    closeDialog();
    try {
      setErrMsg('');
      setSuccessMsg('');
      await deletePatient(patientId);
      setSuccessMsg('Patient deleted successfully.');
    } catch (err) {
      setErrMsg('Failed to delete patient.');
    }
  };

  const closeDialog = () => {
    setShowDeletePatientConfirmation(false);
    onDialogClose?.();
  };

  useEffect(() => {
    setShowDeletePatientConfirmation(showDialog);
  }, [showDialog]);

  return (
    <div>
      {showDeletePatientConfirmation && (
        <ConfirmationDialogV2
          open={showDeletePatientConfirmation}
          titleText={`Are you sure you want to delete patient ${patientName}?`}
          dialogText="This action cannot be undone."
          proceedEvent={onDeletePatientConfirmation}
          proceedEventText={'Yes, delete patient'}
          proceedEventTextColor={'secondary'}
          closeEvent={closeDialog}
        />
      )}
      {!!errMsg && (
        <Text variant="sm" className={style.errorMessage}>
          {errMsg}
        </Text>
      )}
      {!!successMsg && (
        <Text variant="h5" className={style.successMessage}>
          {successMsg}
        </Text>
      )}
    </div>
  );
};

const connectRedux = connect((state: ReduxState) => ({}), {
  deletePatient: deletePersonThunk
});

export default compose(connectRedux)(DeletePatient);
