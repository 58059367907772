import React from 'react';
import IconButton from '@material-ui/core/IconButton';
import { CloseIcon } from 'components/Icons';
import style from './CloseIconButton.scss';
import { Color } from 'utils/color';
import classNames from 'classnames';

type Props = {
  containerClassName?: string;
  onClick: () => void;
};

export const CloseIconButton: React.FC<Props> = ({ onClick, containerClassName }) => {
  return (
    <IconButton className={classNames(style.button, containerClassName)} onClick={onClick}>
      <CloseIcon color={Color.DARK_BLUE_NEW} width={20} height={20} />
    </IconButton>
  );
};
