import React, { useEffect, useState } from 'react';
import DateCalendar from 'react-datepicker';
import { isValid, parse } from 'date-fns';
import { TextField } from '../TextField';
import style from './DatePicker.scss';
import classNames from 'classnames';

export interface ReactDatePickerProps {
  className?: string;
  isMandatory?: boolean;
  date?: Date;
  invalidDateError?: string;
  dateFormat?: string;
  disabled?: boolean;
  errorText?: string;
  label?: string;
  placeholder?: string;
  popperPlacement: string;
  onChange: Function;
}

export const DatePicker: React.FC<ReactDatePickerProps> = ({
  className = '',
  date = null,
  invalidDateError = 'Invalid Date',
  dateFormat = 'MM/dd/yyyy',
  disabled = false,
  errorText = '',
  label,
  placeholder,
  popperPlacement,
  onChange,
  isMandatory
}) => {
  const [selected, setSelected] = useState<Date | null>(date);
  const [inputValue, setInputValue] = useState<string>('');
  const [isInvalidDate, setIsInvalidDate] = useState(false);

  const error = isInvalidDate ? invalidDateError : errorText;

  useEffect(() => {
    setSelected(date);
  }, [date]);

  function handleOnChange(date: Date | null) {
    setSelected(date);
    onChange && onChange(date);
    setIsInvalidDate(false);
  }

  const onChangeInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    const input = event.target.value;
    const parsedDate = parse(input, dateFormat, new Date());
    setInputValue(input);
    if (input.length === 0) {
      handleOnChange(null);
    } else if (isValid(parsedDate) && input.length === dateFormat.length) {
      setSelected(parsedDate);
      handleOnChange(parsedDate);
      setIsInvalidDate(false);
    } else {
      setIsInvalidDate(true);
    }
  };

  return (
    <DateCalendar
      shouldCloseOnSelect
      wrapperClassName={classNames(style.datepicker, className)}
      selected={selected}
      onChange={handleOnChange}
      dateFormat={dateFormat}
      /* @ts-expect-error: expected type issue */
      popperPlacement={popperPlacement}
      placeholderText={placeholder}
      customInput={<TextField disabled={disabled} error={error} label={label} value={inputValue} onChange={onChangeInput} isMandatory={isMandatory} />}
    ></DateCalendar>
  );
};
