import React from 'react';
import style from './NoPatients.scss';
import { ConnectedProps, connect } from 'react-redux';
import { compose } from 'redux';
import { ReduxState } from '../../../redux/types';
import { useNavigate } from 'react-router-dom';
import peopleGroupImg from '../../../assets/images/peopleGroup.png';
import { hasPatientCreatePermissionSelector } from '../../../redux/person/personSelector';
import { Button } from '../../../components/Button/Button';
import plusIcon from '../../../assets/images/plus.png';
import { Text } from '../../../components/Typography/Text';

type PropsFromRedux = ConnectedProps<typeof connectRedux>;

type Props = PropsFromRedux & {};

const NoPatients: React.FC<Props> = ({ hasPatientCreatePermission }) => {
  const navigate = useNavigate();

  return (
    <div className={style.topContainer}>
      <div className={style.subContainer}>
        <img src={peopleGroupImg} className={style.peopleGroupImg} />
        <h1 className={style.headerText}>No patients to display yet</h1>
        <Text className={style.supportingText}>Once you add a patient, their details will appear here, giving you a clear overview of their health journey.</Text>

        {hasPatientCreatePermission && (
          <div className={style.newPatientContainer}>
            <Button className={style.newPatient} variant="contained" onClick={() => navigate('/patients/newPatient')} startIcon={<img src={plusIcon} className={style.plusIcon} />}>
              Add patient
            </Button>
          </div>
        )}
      </div>
    </div>
  );
};

const connectRedux = connect(
  (state: ReduxState) => ({
    hasPatientCreatePermission: hasPatientCreatePermissionSelector(state)
  }),
  {}
);

export default compose(connectRedux)(NoPatients);
