import React from 'react';
import { SVGBasicType } from 'types/global';
import { Color } from 'utils/color';

export const AddCrossIcon = ({ width = 24, height = 24, color = Color.WHITE, backgroundColor = Color.DARK_GREEN }: SVGBasicType) => {
  return (
    <svg width={width} height={height} viewBox="0 0 256 256" xmlSpace="preserve">
      <g
        stroke="none"
        strokeWidth={0}
        strokeDasharray="none"
        strokeLinecap="butt"
        strokeLinejoin="miter"
        strokeMiterlimit={10}
        fill="none"
        fillRule="nonzero"
        opacity={1}
        transform="translate(1.4065934065934016 1.4065934065934016) scale(2.81 2.81)"
      >
        <path
          d="M 45 90 C 20.187 90 0 69.813 0 45 C 0 20.187 20.187 0 45 0 c 24.813 0 45 20.187 45 45 C 90 69.813 69.813 90 45 90 z"
          stroke="none"
          strokeWidth={1}
          strokeDasharray="none"
          strokeLinecap="round"
          strokeLinejoin="miter"
          strokeMiterlimit={10}
          fill={backgroundColor}
          fillRule="nonzero"
          opacity={1}
          transform="matrix(1 0 0 1 0 0)"
        />
        <path
          d="M 45 70.454 c -2.761 0 -5 -2.238 -5 -5 V 24.545 c 0 -2.761 2.239 -5 5 -5 c 2.762 0 5 2.239 5 5 v 40.909 C 50 68.216 47.762 70.454 45 70.454 z"
          stroke="none"
          strokeWidth={1}
          strokeDasharray="none"
          strokeLinecap="round"
          strokeLinejoin="miter"
          strokeMiterlimit={10}
          fill={color}
          fillRule="nonzero"
          opacity={1}
          transform="matrix(1 0 0 1 0 0)"
        />
        <path
          d="M 65.454 50 H 24.545 c -2.761 0 -5 -2.238 -5 -5 c 0 -2.761 2.239 -5 5 -5 h 40.909 c 2.762 0 5 2.239 5 5 C 70.454 47.762 68.216 50 65.454 50 z"
          stroke="none"
          strokeWidth={1}
          strokeDasharray="none"
          strokeLinecap="round"
          strokeLinejoin="miter"
          strokeMiterlimit={10}
          fill={color}
          fillRule="nonzero"
          opacity={1}
          transform="matrix(1 0 0 1 0 0)"
        />
      </g>
    </svg>
  );
};
