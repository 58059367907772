import React from 'react';
import { SVGBasicType } from 'types/global';
import { Color } from 'utils/color';

export const CancelCrossIcon = ({ width = 24, height = 24, color = Color.WHITE, backgroundColor = Color.RED_700 }: SVGBasicType) => {
  return (
    <svg width={width} height={height} viewBox="0 0 256 256" xmlSpace="preserve">
      <g
        stroke="none"
        strokeWidth={0}
        strokeDasharray="none"
        strokeLinecap="butt"
        strokeLinejoin="miter"
        strokeMiterlimit={10}
        fill="none"
        fillRule="nonzero"
        opacity={1}
        transform="translate(1.4065934065934016 1.4065934065934016) scale(2.81 2.81)"
      >
        <path
          d="M 45 90 C 20.187 90 0 69.813 0 45 C 0 20.187 20.187 0 45 0 c 24.813 0 45 20.187 45 45 C 90 69.813 69.813 90 45 90 z"
          stroke="none"
          strokeWidth={1}
          strokeDasharray="none"
          strokeLinecap="round"
          strokeLinejoin="miter"
          strokeMiterlimit={10}
          fill={backgroundColor}
          fillRule="nonzero"
          opacity={1}
          transform="matrix(1 0 0 1 0 0)"
        />
        <path
          d="M 28.902 66.098 c -1.28 0 -2.559 -0.488 -3.536 -1.465 c -1.953 -1.952 -1.953 -5.118 0 -7.07 l 32.196 -32.196 c 1.951 -1.952 5.119 -1.952 7.07 0 c 1.953 1.953 1.953 5.119 0 7.071 L 32.438 64.633 C 31.461 65.609 30.182 66.098 28.902 66.098 z"
          stroke="none"
          strokeWidth={1}
          strokeDasharray="none"
          strokeLinecap="round"
          strokeLinejoin="miter"
          strokeMiterlimit={10}
          fill={color}
          fillRule="nonzero"
          opacity={1}
          transform="matrix(1 0 0 1 0 0)"
        />
        <path
          d="M 61.098 66.098 c -1.279 0 -2.56 -0.488 -3.535 -1.465 L 25.367 32.438 c -1.953 -1.953 -1.953 -5.119 0 -7.071 c 1.953 -1.952 5.118 -1.952 7.071 0 l 32.195 32.196 c 1.953 1.952 1.953 5.118 0 7.07 C 63.657 65.609 62.377 66.098 61.098 66.098 z"
          stroke="none"
          strokeWidth={1}
          strokeDasharray="none"
          strokeLinecap="round"
          strokeLinejoin="miter"
          strokeMiterlimit={10}
          fill={color}
          fillRule="nonzero"
          opacity={1}
          transform="matrix(1 0 0 1 0 0)"
        />
      </g>
    </svg>
  );
};
