import { Text } from 'components/Typography';
import style from './AllOrganizationsTable.scss';
import { Button } from 'components/Button/Button';
import React from 'react';
import { Organization } from 'redux/organization/organizationTypes';

type Props = {
  onEditPress: (org: Organization) => void;
  dataList?: Organization[];
};

export const AllOrganizationsTable: React.FC<Props> = ({ onEditPress, dataList = [] }) => (
  <>
    <Text variant="h5">All Organizations</Text>
    <table className={style.table}>
      <thead>
        <tr>
          <th>
            <Text variant="sm">ID</Text>
          </th>
          <th>
            <Text variant="sm">Name</Text>
          </th>
          <th>
            <Text variant="sm">Logo Image Url</Text>
          </th>
          <th>
            <Text variant="sm">Logo Image</Text>
          </th>
          <th>
            <Text variant="sm">Created</Text>
          </th>
          <th>
            <Text variant="sm">Edit</Text>
          </th>
        </tr>
      </thead>
      <tbody>
        {dataList.map((org: Organization) => (
          <tr key={org.id}>
            <td>
              <Text variant="sm">{org.id}</Text>
            </td>
            <td>
              <Text variant="sm">{org.name}</Text>
            </td>
            <td className={style.wrap}>
              <Text variant="sm" className={style.textWrap}>
                {org.logoImageUrl}
              </Text>
            </td>
            <td>
              <img src={org.logoImageUrl} alt="Logo" className={style.logoImage} />
            </td>
            <td>
              <Text variant="sm">{org.created}</Text>
            </td>
            <td>
              <Button variant="contained" onClick={() => onEditPress(org)}>
                Edit
              </Button>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  </>
);
