import React, { useEffect, useState } from 'react';
import { ConnectedProps, connect } from 'react-redux';
import { compose } from 'redux';
import {
  CarePlanAutocomplete,
  CarePlanAutocompletePaper,
  CarePlanAutocompletePopper,
  CarePlanAutocompleteTextField as CarePlanTextField
} from '../../components/CarePlanAutocomplete/CarePlanAutocomplete';
import { getErrorPostNewPatientSelector, getPersonByIdSelector, hasPatientCreatePermissionSelector, isLoadingPostNewPatientSelector } from '../../redux/person/personSelector';
import { getPersonByIdThunk, postNewPatientThunk, putUpdatePatientThunk } from '../../redux/person/personThunks';
import {
  GenderType,
  NewPatientRequest,
  PronounTypeList,
  genderTypeList,
  PronounType,
  USStateList,
  Person,
  UpdatePatientRequest,
  LocationType
} from '../../redux/person/personTypes';
import { ReduxState } from '../../redux/types';
import { normalizeEnumName } from '../../utils/content';
import { emailAlertMessage, getCleanPhoneNumber, getEnumKey, getFormattedPhoneNumber, isEmailValid, isPhoneNumberValid, phoneNumberAlertMessage } from '../../utils/util';
import style from './NewEditPatient.scss';
import { Button } from '../../components/Button/Button';
import { Text } from '../../components/Typography/Text';
import { TextField } from '../../components/TextField';
import envelopeSimpleIcon from '../../assets/images/envelope-simple.png';
import caretDownIcon from '../../assets/images/caret-down.png';
import { DatePicker } from '../../components/DatePicker';
import { Location as PatientLocation } from '../../redux/device/deviceTypes';
import NavTopBarContentWrapper from '../../components/NavTopBarContentWrapper/NavTopBarContentWrapper';
import { useNavigate, useParams } from 'react-router-dom';

type PropsFromRedux = ConnectedProps<typeof connectRedux>;
type Props = PropsFromRedux & {};

const personToNewPatientRequest = (person: Person): NewPatientRequest => {
  return {
    ...person,
    givenNames: person.givenNames ?? '',
    familyName: person.familyName ?? '',
    phoneNumber: getFormattedPhoneNumber(person.phoneNumber ?? ''),
    email: person.email ?? '',
    birthDate: person.birthDate ?? ''
  };
};

const emptyPatient: NewPatientRequest = {
  givenNames: '',
  familyName: '',
  phoneNumber: '',
  nickname: '',
  email: '',
  birthDate: '',
  genderType: null,
  pronounType: null,
  shippingAddress: {} as PatientLocation
};

const NewEditPatient: React.FC<Props> = ({
  hasPatientCreatePermission,
  isLoadingPostNewPatient,
  postNewPatient,
  errorPostNewPatient,
  getPersonById,
  fetchPersonById,
  updatePatient
}) => {
  if (!hasPatientCreatePermission) {
    return null;
  }

  const params = useParams();
  const patientId = params?.userId ?? '';
  const isCreateMode = !patientId;
  const [personFormData, setPersonFormData] = useState<NewPatientRequest>(emptyPatient);
  const navigate = useNavigate();

  const [errors, setErrors] = React.useState({
    phoneNumber: '',
    email: '',
    birthDate: ''
  });
  const [successMessage, setSuccessMessage] = React.useState('');
  const [apiError, setApiError] = React.useState('');

  useEffect(() => {
    patientId && fetchPersonById(patientId);
  }, [patientId]);

  useEffect(() => {
    if (!patientId) {
      return;
    }
    const person = getPersonById[patientId];
    setPersonFormData(person ? personToNewPatientRequest(person) : emptyPatient);
  }, [getPersonById, patientId]);

  const isSubmitBtnDisabled =
    isLoadingPostNewPatient || !personFormData.givenNames || !personFormData.familyName || !personFormData.phoneNumber || !personFormData.email || !personFormData.birthDate;

  useEffect(() => {
    if (errorPostNewPatient && errorPostNewPatient.status === 401) {
      const regex = new RegExp(`Email .* is already in use`, 'i');
      if (regex.test(errorPostNewPatient.message)) {
        setErrors({ ...errors, email: 'Email is already in use' });
      }
    }
  }, [errorPostNewPatient]);

  const handlePersonChange = (e) => {
    const { name, value } = e.target;
    handlePersonDataChange(name, value);
  };

  const handleShippingAddressChange = (e) => {
    const { name, value } = e.target;
    handleShippingDataChange(name, value);
  };

  const handleShippingDataChange = (name, value) => {
    setSuccessMessage('');
    setApiError('');
    setPersonFormData({
      ...personFormData,
      shippingAddress: {
        ...personFormData.shippingAddress,
        [name]: value
      }
    });
  };

  const handlePhoneNumberChange = (e) => {
    const value = e.target.value;
    const formattedPhoneNumber = getFormattedPhoneNumber(value);
    handlePersonDataChange('phoneNumber', formattedPhoneNumber);
  };

  const handleBirthDateChange = (date: Date) => {
    handlePersonDataChange('birthDate', date.toISOString());
  };

  const handleGenderTypeChange = (event, value) => {
    const gender = getEnumKey(GenderType, value);
    handlePersonDataChange('genderType', gender);
  };

  const handlePronounTypeChange = (event, value) => {
    const pronoun = getEnumKey(PronounType, value);
    handlePersonDataChange('pronounType', pronoun);
  };

  const handleStateChange = (event, value) => {
    handleShippingDataChange('state', value);
  };

  const handlePersonDataChange = (name, value) => {
    setSuccessMessage('');
    setApiError('');
    setPersonFormData({
      ...personFormData,
      [name]: value
    });
  };

  const validateForm = () => {
    const newErrors = {
      phoneNumber: isPhoneNumberValid(personFormData.phoneNumber) ? '' : phoneNumberAlertMessage(personFormData.phoneNumber) || '',
      email: isEmailValid(personFormData.email) ? '' : emailAlertMessage(personFormData.email) || '',
      birthDate: '',
      genderType: ''
    };

    setErrors(newErrors);

    return Object.values(newErrors).every((error) => error === '');
  };

  const handleFormSubmit = async (event) => {
    event.preventDefault();
    if (validateForm()) {
      const cleanPhoneNumber = getCleanPhoneNumber(personFormData.phoneNumber);
      setApiError('');
      setSuccessMessage('');
      try {
        if (isCreateMode) {
          const newPatientData: NewPatientRequest = {
            ...personFormData,
            genderType: personFormData.genderType,
            pronounType: personFormData.pronounType,
            phoneNumber: cleanPhoneNumber
          };
          await postNewPatient(newPatientData);
          setSuccessMessage('Patient was successfully created!');
          setPersonFormData(emptyPatient);
          return;
        }

        // Edit patient
        const patient = getPersonById[patientId];
        if (!patient || !patient.id) {
          setApiError('Patient not found');
          return;
        }
        const updatedPatientData: UpdatePatientRequest = {
          person: {
            ...patient,
            ...personFormData,
            shippingAddress: null
          },
          shippingAddress: {
            ...patient.shippingAddress,
            ...personFormData.shippingAddress,
            id: '',
            locationType: patient?.shippingAddress?.locationType ?? LocationType.SHIPPING_ADDRESS
          }
        };
        await updatePatient(updatedPatientData);
        setSuccessMessage('Patient was successfully updated!');
        fetchPersonById(patientId);
      } catch (err) {
        setApiError('Something went wrong. Please try again.');
      }
    }
  };

  return (
    <NavTopBarContentWrapper headerText={isCreateMode ? 'Create New Patient' : 'Edit Patient'}>
      <form onSubmit={handleFormSubmit} className={style.mainContainer}>
        <div className={style.patientInfoContainer}>
          <Text variant="xl">Patient info</Text>
          <br />
          <div className={style.subContainer}>
            <div className={style.formRow}>
              {/* FIRST NAME */}
              <TextField
                isMandatory
                name="givenNames"
                value={personFormData.givenNames}
                onChange={handlePersonChange}
                label="First name"
                placeholder="First name"
                autoComplete="firstName"
              />
              {/* LAST NAME */}
              <TextField
                isMandatory
                name="familyName"
                value={personFormData.familyName}
                onChange={handlePersonChange}
                label="Last name"
                placeholder="Last name"
                autoComplete="lastName"
              />
            </div>
            <div className={style.formRow}>
              {/* EMAIL */}
              {isCreateMode && (
                <TextField
                  isMandatory
                  name="email"
                  value={personFormData.email}
                  onChange={handlePersonChange}
                  label="Email address"
                  placeholder="Email address"
                  autoComplete="email"
                  error={errors.email}
                />
              )}

              {/* PHONE */}
              <TextField
                isMandatory
                name="phoneNumber"
                value={personFormData.phoneNumber}
                onChange={handlePhoneNumberChange}
                label="Mobile number"
                placeholder="+1 (555) 000-0000"
                autoComplete="phoneNumber"
                error={errors.phoneNumber}
              />
            </div>
            <DatePicker
              isMandatory
              date={personFormData.birthDate ? new Date(personFormData.birthDate) : undefined}
              onChange={handleBirthDateChange}
              popperPlacement="bottom-start"
              dateFormat="MM/dd/yyyy"
              label="Date of birth"
              placeholder="mm/dd/yyyy"
            />
            <div className={style.formRow}>
              {/* NICKNAME */}
              <TextField name="nickname" value={personFormData.nickname ?? ''} onChange={handlePersonChange} label="Nickname" placeholder="Nickname" autoComplete="nickname" />
            </div>
            <div className={style.formRow}>
              {/* GENDER */}
              <div className={style.subContainerEvenDistribution}>
                <Text variant="sm" className={style.labelSelect}>
                  Gender
                </Text>
                <CarePlanAutocomplete
                  options={genderTypeList}
                  value={personFormData.genderType || null} // Ensure value is not undefined
                  getOptionLabel={(genderType) => GenderType[genderType as GenderType]}
                  onChange={handleGenderTypeChange}
                  PaperComponent={CarePlanAutocompletePaper}
                  PopperComponent={CarePlanAutocompletePopper}
                  renderInput={(params) => <CarePlanTextField {...params} placeholder="Select" />}
                  renderOption={(props, option, state) => (
                    <li {...props} key={option as GenderType}>
                      <div>{option as GenderType}</div>
                    </li>
                  )}
                />
              </div>
              {/* pronoun */}
              <div className={style.subContainerEvenDistribution}>
                <Text variant="sm" className={style.labelSelect}>
                  Pronouns
                </Text>
                <CarePlanAutocomplete
                  options={PronounTypeList}
                  value={personFormData.pronounType || null} // Ensure value is not undefined
                  getOptionLabel={(pronounType) => PronounType[pronounType as PronounType]}
                  onChange={handlePronounTypeChange}
                  PaperComponent={CarePlanAutocompletePaper}
                  PopperComponent={CarePlanAutocompletePopper}
                  renderInput={(params) => <CarePlanTextField {...params} placeholder="Select" />}
                  renderOption={(props, option, state) => (
                    <li {...props} key={option as PronounType}>
                      <div>{option as PronounType}</div>
                    </li>
                  )}
                />
              </div>
            </div>
          </div>
        </div>
        <div className={style.addressContainer}>
          <Text variant="xl">Address</Text>
          <br />
          <div className={style.subContainer}>
            <div className={style.formRow}>
              {/*ADDRESS LINE1 */}
              <TextField
                name="street"
                className={style.street}
                value={personFormData.shippingAddress?.street ?? ''}
                onChange={handleShippingAddressChange}
                label="Address line 1"
                placeholder="Street address or P.O. box"
                autoComplete="street"
              />
              {/* POSTAL CODE */}
              <TextField
                name="postalCode"
                className={style.postalCode}
                value={personFormData.shippingAddress?.postalCode ?? ''}
                onChange={handleShippingAddressChange}
                label="ZIP code"
                placeholder="ZIP code"
                autoComplete="postalCode"
              />
            </div>
            <div className={style.formRow}>
              {/*STREET SECOND LINE*/}
              <TextField
                name="streetSecondLine"
                value={personFormData.shippingAddress?.streetSecondLine ?? ''}
                onChange={handleShippingAddressChange}
                label="Address line 2"
                placeholder="Apt, suite, unit, building, floor"
                autoComplete="streetSecondLine"
              />
            </div>
            <div className={style.formRow}>
              {/* CITY */}
              <div className={style.subContainerEvenDistribution}>
                <TextField
                  name="city"
                  value={personFormData.shippingAddress?.city ?? ''}
                  onChange={handleShippingAddressChange}
                  label="City"
                  placeholder="City"
                  autoComplete="city"
                />
              </div>

              {/* STATE */}
              <div className={style.subContainerEvenDistribution}>
                <Text variant="sm" className={style.labelSelect}>
                  State
                </Text>
                <CarePlanAutocomplete
                  options={USStateList}
                  value={personFormData.shippingAddress?.state ?? ''} // Ensure value is not undefined
                  onChange={handleStateChange}
                  PaperComponent={CarePlanAutocompletePaper}
                  PopperComponent={CarePlanAutocompletePopper}
                  renderInput={(params) => <CarePlanTextField {...params} placeholder="Select" />}
                  renderOption={(props, option, state) => (
                    <li {...props} key={option as string}>
                      <div>{option as string}</div>
                    </li>
                  )}
                />
              </div>
            </div>
          </div>
        </div>

        <div className={style.submitButtonContainer}>
          <div>
            {!!apiError && (
              <Text variant="sm" className={style.apiError}>
                {apiError}
              </Text>
            )}
            {!!successMessage && (
              <Text variant="sm" className={style.successMessage}>
                {successMessage}
              </Text>
            )}
          </div>
          <div>
            <Button className={style.buttonCancel} onClick={() => navigate(-1)}>
              Cancel
            </Button>
            <Button type="submit" variant="contained" disabled={isSubmitBtnDisabled} className={style.button}>
              Submit
            </Button>
          </div>
        </div>
      </form>
    </NavTopBarContentWrapper>
  );
};

const connectRedux = connect(
  (state: ReduxState) => ({
    isLoadingPostNewPatient: isLoadingPostNewPatientSelector(state),
    hasPatientCreatePermission: hasPatientCreatePermissionSelector(state),
    errorPostNewPatient: getErrorPostNewPatientSelector(state),
    getPersonById: getPersonByIdSelector(state)
  }),
  {
    postNewPatient: postNewPatientThunk,
    fetchPersonById: getPersonByIdThunk,
    updatePatient: putUpdatePatientThunk
  }
);

export default compose(connectRedux)(NewEditPatient);
