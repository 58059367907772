import React from 'react';
import style from './ComingSoonCard.scss';
import { Text } from '../../../../components/Typography';
import { ComingSoonLabel } from 'containers/ComingSoonLabel/ComingSoonLabel';

export type ComingSoonProps = {
  title?: string;
  imgSrc?: string;
  icon: React.FC;
  size?: 'sm' | 'lg';
};

export const ComingSoonCard: React.FC<ComingSoonProps> = ({ title, icon: Icon, size = 'lg' }) => {
  const isLarge = size === 'lg';
  return (
    <div className={style.card}>
      <div className={style.content}>
        <Icon />
        <div>
          <Text variant={isLarge ? 'xl' : 'lg'} className={style.title}>
            {title}
          </Text>
          <ComingSoonLabel containerClassName={style.label} />
        </div>
        <div className={style.stub} />
      </div>
    </div>
  );
};
