import React, { useState, useEffect, useCallback } from 'react';
import { Chart } from 'react-google-charts';
import style from './Insights.scss';
import { Color } from '../../../../utils/color';
import { PersonVitalInsights, PersonVitalTimeFrame, PersonVitalType, VitalSignsStatus } from '../../../../redux/person/personTypes';
import { convertUTCToLocalizedDateTime, getDateFromStringIgnoreTimezone } from '../../../../utils/time';
import { getFixedPointDisplay } from '../../../../utils/util';
import tableCircle from '../../../../assets/images/tableCircle.png';
import { Text } from 'components/Typography';
import { generateChartTicks, getMinTime, getYMaxValue, getYMinValue, updateControlLinesStyles, updateHeaderStyles, getChartColor, createCustomTooltip } from './Insights.utils';
import moment from 'moment-timezone';

export type InsightsChartProps = {
  timeZoneId: string;
  personVitalInsights?: PersonVitalInsights | null;
  vitalType: PersonVitalType;
  timeFrame: PersonVitalTimeFrame;
  minRate?: number;
  maxRate?: number;
  minControlRate?: number;
  maxControlRate?: number;
};

type InsightsChartDataType = Array<Array<Date | number | string>>;

const getDateFormat = (timeFrame?: PersonVitalTimeFrame) => {
  switch (timeFrame) {
    case PersonVitalTimeFrame.TWENTY_FOUR_HOURS:
      // return 'hh:mm a';
      return null;
    case PersonVitalTimeFrame.SEVEN_DAYS:
      return 'EEE, d MMM';
    case PersonVitalTimeFrame.THIRTY_DAYS:
      return 'd MMM';
    case PersonVitalTimeFrame.TWELVE_MONTHS:
      return 'MMM yy';
    default:
      return null;
  }
};

const getXAsisGridlineCount = (timeFrame?: PersonVitalTimeFrame) => {
  switch (timeFrame) {
    case PersonVitalTimeFrame.TWENTY_FOUR_HOURS:
      return 12;
    case PersonVitalTimeFrame.SEVEN_DAYS:
      return 7;
    case PersonVitalTimeFrame.THIRTY_DAYS:
      return 15;
    case PersonVitalTimeFrame.TWELVE_MONTHS:
      return 12;
    default:
      return 12;
  }
};

const getDateForTimeZone = (dateStr: string, timeZoneId: string) => {
  const localDateTime = convertUTCToLocalizedDateTime(dateStr, timeZoneId);
  return new Date(localDateTime.year, localDateTime.month - 1, localDateTime.day, localDateTime.hour, localDateTime.minute, localDateTime.second);
};

export const InsightsChart: React.FC<InsightsChartProps> = ({ timeZoneId, personVitalInsights, vitalType, timeFrame, minRate, maxRate, minControlRate, maxControlRate }) => {
  const [chartData, setChartData] = useState<any[]>([]);
  const yMinValue = getYMinValue(vitalType, minRate, minControlRate);
  const yMaxValue = getYMaxValue(vitalType, maxRate, maxControlRate);
  const patLocalTime = moment.tz(timeZoneId).format('YYYY-MM-DD HH:mm:ss z');
  const xMaxValue = getDateFromStringIgnoreTimezone(patLocalTime)!;
  const xMinValue = getMinTime(xMaxValue, timeFrame);
  const chartColor = getChartColor(vitalType);
  const xAsisGridlineCount = getXAsisGridlineCount(timeFrame);
  const title = vitalType === PersonVitalType.HEART_RATE ? 'Heart rate (bpm)' : 'Breathing rate (bpm)';
  const [options, setOptions] = useState<any>(null);

  const isDateValid = (dt: Date | null) => {
    if (dt && dt >= xMinValue && dt <= xMaxValue) {
      return true;
    }
    return false;
  };

  const isValueValid = (value: number | undefined) => {
    return typeof value === 'number' && !Number.isNaN(value);
  };

  const getChartData = (personVitalInsights?: PersonVitalInsights | null): InsightsChartDataType => {
    if (!personVitalInsights || !personVitalInsights.listOfAggregatedProcessedVitalSigns) {
      return [];
    }
    const aggregatedProcessedVitalSigns = personVitalInsights.listOfAggregatedProcessedVitalSigns;
    return aggregatedProcessedVitalSigns
      .map((vitalSign) => {
        const min = vitalType === PersonVitalType.HEART_RATE ? vitalSign.minHeartRate : vitalSign.minBreathingRate;
        const max = vitalType === PersonVitalType.HEART_RATE ? vitalSign.maxHeartRate : vitalSign.maxBreathingRate;
        const status = vitalType === PersonVitalType.HEART_RATE ? vitalSign.heartRateStatus : vitalSign.breathingRateStatus;
        const bucket = getDateFromStringIgnoreTimezone(vitalSign.bucket);
        const start = getDateFromStringIgnoreTimezone(vitalSign.startTime);
        const end = getDateFromStringIgnoreTimezone(vitalSign.endTime);

        if (isValueValid(min) && isValueValid(max) && isDateValid(start) && isDateValid(end) && isDateValid(bucket)) {
          const minVal = getFixedPointDisplay(min);
          const maxVal = getFixedPointDisplay(max);

          return [bucket!, minVal, minVal, maxVal, maxVal, createCustomTooltip(start!, end!, minVal, maxVal, timeFrame, status)];
        }
        return [];
      })
      .filter((data) => data.length > 0);
  };

  useEffect(() => {
    const fetchData = async () => {
      const data = getChartData(personVitalInsights);
      setChartData([['Date', 'Low', 'Open', 'Close', 'High', { type: 'string', role: 'tooltip', p: { html: true } }], ...data]);
      setOptions(getOptions());
    };

    fetchData();
  }, [personVitalInsights]);

  const getOptions = () => {
    return {
      legend: 'none',
      title,
      titleTextStyle: {
        color: chartColor,
        fontSize: 12,
        lineHeight: 18,
        marginLeft: -32
      },
      tooltip: { isHtml: true },
      bar: {
        groupWidth: 14
      },
      fontName: 'Outfit',
      colors: [chartColor],
      chartArea: {
        left: 32,
        top: 18,
        bottom: 24,
        marginTop: -16,
        right: 20,
        width: '100%',
        height: '100%'
      },
      hAxis: {
        title: '',
        textStyle: {
          color: Color.GRAY,
          fontSize: 12,
          lineHeight: 18
        },
        viewWindowMode: 'explicit',
        minValue: xMinValue,
        maxValue: xMaxValue,
        viewWindow: {
          min: xMinValue,
          max: xMaxValue
        },
        format: getDateFormat(timeFrame),
        gridlines: {
          count: xAsisGridlineCount,
          color: 'transparent'
        },
        minorGridlines: {
          count: 0
        }
      },
      vAxis: {
        ticks: [...generateChartTicks(yMinValue, yMaxValue), { v: minControlRate, f: minControlRate }, { v: maxControlRate, f: maxControlRate }] as (number | Date)[],
        title: '',
        textStyle: {
          color: chartColor,
          fontSize: 12,
          lineHeight: 18
        },
        minValue: yMinValue,
        maxValue: yMaxValue,
        viewWindow: {
          min: yMinValue,
          max: yMaxValue
        },
        gridlines: {
          color: Color.GRAY_100
        },
        minorGridlines: {
          count: 0
        },
        baselineColor: Color.GRAY_100
      }
    };
  };

  const updateChartStyles = useCallback(() => {
    updateHeaderStyles();
    updateControlLinesStyles(minControlRate, maxControlRate);
    //updateCandleStickStyles();
  }, [minControlRate, maxControlRate]);

  return chartData?.length > 1 ? (
    <div className={style.chartContainer}>
      <Chart
        width="100%"
        height="100%"
        chartEvents={[{ eventName: 'ready', callback: updateChartStyles }]}
        chartType="CandlestickChart"
        loader={<div>Loading Chart</div>}
        data={chartData}
        options={options}
      />
    </div>
  ) : (
    <div className={style.noDataChartContainer}>
      <img src={tableCircle} alt="no data image" />
      <Text>No data to display</Text>
    </div>
  );
};
