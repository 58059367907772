import { createReducer } from '@reduxjs/toolkit';
import { CareTeam } from 'redux/clinical/clinicalTypes';
import { getSleepSchedulesForReviewActions } from '../../redux/sleepschedule/sleepScheduleActions';
import { SleepSchedule } from '../../redux/sleepschedule/sleepScheduleTypes';
import { deviceShipmentSorter } from '../../utils/util';
import { ChatChannelType } from '../chat/chatTypes';
import { getCareTeamPersonsAction, getCareTeamsAction } from '../clinical/clinicalActions';
import { createAdHocBatchContentActions } from '../content/contentActions';
import { getDevicePersonOverviewActions, postUnlinkDeviceActions } from '../device/deviceActions';
import { DeviceShipment, GetDevicePersonPayload } from '../device/deviceTypes';
import { getDemoAccessCodesAction, getLoginActions, getRequestRegisterActions } from '../oauth/oauthActions';
import { getActionStringWithoutState } from '../reduxUtils';
import { ReduxAction } from '../types';
import {
  PERSON_ACTION_PREFIX,
  calculateSleepScheduleActions,
  clearPersonResponseStatusAction,
  clearSleepCalculationAction,
  clearUpdatePersonProgramAction,
  getAllPermissionRolesActions,
  getPersonAndPermissionsByOrgIdAction,
  getPersonByIdActions,
  getPersonDetailsActions,
  getPersonOverviewActions,
  getPersonOverviewCountActions,
  getPersonPermissionsActions,
  getPersonPermissionsByEmailActions,
  getPersonSettingsActions,
  getPersonSettingsProgramByEmailActions,
  getPersonVitalsAction,
  getPersonVitalInsightsAction,
  getSleepSchedulesActions,
  getValidSleepSchedulesActions,
  // permissionRoleUpsertActions,
  postNewPatientAction,
  postNewTeamMemberAction,
  putAccountStatusActions,
  putChatBotEnabledActions,
  putDeviceGroupActions,
  putEnableDeviceTrainingActions,
  putEsIdActions,
  putLunaSleepLogSummariesEnabledActions,
  putLunaSleepScheduleSummariesEnabledActions,
  putLunaWeeklySummariesEnabledActions,
  putMobileCustomSleepStoriesEnabledActions,
  putPersonPermissionRoleActions,
  putShowSleepFeaturesActions,
  putSleepLogAutoSubmissionEnabledActions,
  putSleepLogDeviceTimesEnabledActions,
  putSleepScheduleEnabledActions,
  removePersonPermissionRoleActions,
  saveIsiScoreActions,
  saveOrderActions,
  saveShipmentActions,
  saveSleepScheduleAndCalculationsActions,
  sendPersonPushNotificationActions,
  sendPushNotificationsActions,
  setSelectedPatientIdAction,
  updatePersonProgramStepActions,
  updatePersonSettingsActions,
  updateSleepScheduleActions,
  userPermissionsActions,
  getPersonVitalOverviewAction,
  deletePersonAction,
  updatePatientAction
  // userPermissionsActions
} from './personActions';
import {
  DeviceGroup,
  DeviceTraining,
  Person,
  PersonAccountStatus,
  PersonDetails,
  PersonDetailsResponse,
  PersonIsiScore,
  PersonOrder,
  PersonOverview,
  PersonOverviewResponse,
  PersonPermissionRole,
  PersonPermissionsResponse,
  PersonSetting,
  PersonState,
  SensorPerson,
  SleepScheduleCalculationResponse,
  WorkflowUpdatingResponse
} from './personTypes';

export const initialPersonState: PersonState = {
  isLoading: {},
  isLoadingUserPermissions: false,
  error: {},
  success: {},
  personById: {},
  personSettingById: {},
  personOverviewById: {},
  sensorPersonById: {},
  devicePersonByPersonId: {},
  personSleepProgramById: {},
  clientDevicePushInfoById: {},
  permissionRoleById: {},
  personPermissionRoleById: {},
  personPermissionRoleIdsByPersonId: {},
  permissionRoleTypeById: {},
  personOrderById: {},
  personIsiScoreById: {},
  personAccountStatusById: {},
  deviceTrainingById: {},
  deviceShipmentById: {},
  clientDeviceById: {},
  personPermissionsById: {},
  isPersonSettingProgramLoading: false,
  isSendPersonPushNotificationLoading: false,
  isUpdatePersonSettingsLoading: false,
  isUpdateSleepScheduleLoading: false,
  personOverviewList: [],
  personOverviewCount: 0,
  lastUserOverviewInitialLoad: 0,
  batchLoading: false,
  batchSuccess: false,
  pageLoading: false,
  kokoMapSleepScheduleIds: [],
  sleepScheduleById: {},
  successMessage: undefined,
  warningMessage: undefined,
  loadingEventChange: false,
  isLoadingPostNewPatient: false,
  isLoadingPostNewTeamMember: false,
  personVitalsByPersonId: {}
};

const defaultActionStart = (state, action) => {
  const actionTag = getActionStringWithoutState(action.type);
  state.isLoading[actionTag] = true;
  delete state.error[actionTag];
};

const defaultActionFail = (state, action) => {
  const actionTag = getActionStringWithoutState(action.type);
  state.isLoading[actionTag] = false;
  state.error[actionTag] = {
    message: action.payload?.message,
    status: action.payload.status
  };
};

export default createReducer(initialPersonState, (builder) => {
  builder
    .addCase(putAccountStatusActions.start, (state, action) => {
      state.loadingEventChange = true;
    })
    .addCase(putDeviceGroupActions.start, (state, action) => {
      state.loadingEventChange = true;
    })
    .addCase(saveSleepScheduleAndCalculationsActions.start, (state, action) => {
      state.loadingEventChange = true;
      state.currentSleepScheduleCalculation = undefined;
    })
    .addCase(putAccountStatusActions.fail, (state, action) => {
      state.loadingEventChange = false;
    })
    .addCase(putDeviceGroupActions.fail, (state, action) => {
      state.loadingEventChange = false;
    })
    .addCase(saveSleepScheduleAndCalculationsActions.fail, (state, action) => {
      state.loadingEventChange = false;
      state.currentSleepScheduleCalculation = undefined;
    })
    .addCase(getPersonByIdActions.success, (state, action) => {
      const person: Person = action.payload;
      person.lastUpdated = new Date().getTime();
      state.personById[person.id] = person;
    })
    .addCase(postNewPatientAction.start, (state, action) => {
      state.isLoadingPostNewPatient = true;
      state.errorPostNewPatient = undefined;
    })
    .addCase(postNewPatientAction.success, (state, action) => {
      const person: Person = action.payload;
      person.lastUpdated = new Date().getTime();
      state.personById[person.id] = person;
      state.isLoadingPostNewPatient = false;
    })
    .addCase(postNewPatientAction.fail, (state, action) => {
      state.isLoadingPostNewPatient = false;
      state.errorPostNewPatient = action.payload;
    })
    .addCase(postNewTeamMemberAction.start, (state, action) => {
      state.isLoadingPostNewTeamMember = true;
    })
    .addCase(postNewTeamMemberAction.success, (state, action) => {
      const person: Person = action.payload;
      person.lastUpdated = new Date().getTime();
      state.personById[person.id] = person;
      state.isLoadingPostNewTeamMember = false;
    })
    .addCase(postNewTeamMemberAction.fail, (state, action) => {
      state.isLoadingPostNewTeamMember = false;
      state.errorPostNewTeamMember = action.payload;
    })
    .addCase(getDevicePersonOverviewActions.success, (state, action) => {
      const devicePersonResponse: GetDevicePersonPayload = action.payload;
      devicePersonResponse?.forEach((devicePersonLocationResponse) => {
        if (devicePersonLocationResponse?.person?.id) {
          state.personById[devicePersonLocationResponse.person.id] = devicePersonLocationResponse.person;
        }
        if (devicePersonLocationResponse?.sensorPerson?.id) {
          state.sensorPersonById[devicePersonLocationResponse.sensorPerson.id] = devicePersonLocationResponse.sensorPerson;
        }
        if (devicePersonLocationResponse?.person?.id && devicePersonLocationResponse?.sensorPerson?.id) {
          state.devicePersonByPersonId[devicePersonLocationResponse?.person?.id] = devicePersonLocationResponse.device;
        }
      });
    })
    .addCase(getPersonSettingsProgramByEmailActions.start, (state, action) => {
      state.isPersonSettingProgramLoading = true;
      delete state.lastLoadedPersonId;
    })
    .addCase(getPersonSettingsProgramByEmailActions.success, (state, action) => {
      const person = action.payload;
      if (person.id) {
        state.personById[person.id] = person;
        state.lastLoadedPersonId = person.id;
      }
      state.isPersonSettingProgramLoading = false;
    })
    .addCase(getPersonSettingsProgramByEmailActions.fail, (state, action) => {
      state.isPersonSettingProgramLoading = false;
      state.personSettingProgramError = action.payload?.message;
    })
    .addCase(updatePersonSettingsActions.start, (state, action) => {
      state.isUpdatePersonSettingsLoading = true;
      clearAdminPanelApiErrors(state);
    })
    .addCase(updatePersonSettingsActions.success, (state, action) => {
      state.isUpdatePersonSettingsLoading = false;
      const personSetting = action.payload;
      if (personSetting?.id) {
        const person = state.personById[personSetting.id];
        person.personSettings = personSetting;
        state.personById[personSetting.id] = person;
        state.lastLoadedPersonId = person.id;
      }
    })
    .addCase(updatePersonSettingsActions.fail, (state, action) => {
      state.isUpdatePersonSettingsLoading = false;
      state.updatePersonSettingsError = action.payload?.message;
    })
    .addCase(updateSleepScheduleActions.start, (state, action) => {
      state.isUpdateSleepScheduleLoading = true;
    })
    .addCase(updateSleepScheduleActions.success, (state, action) => {
      state.isUpdateSleepScheduleLoading = false;
      const sleepSchedule = action.payload;
      if (sleepSchedule?.personId) {
        const person: Person = state.personById[sleepSchedule.personId];
        if (person) {
          person.currentSleepSchedule = sleepSchedule;
          state.personById[sleepSchedule.personId] = person;
        }
      }
      state.success[action.type] = true;
    })
    .addCase(updateSleepScheduleActions.fail, (state, action) => {
      state.error[action.type] = {
        message: action.payload?.message,
        status: action.payload.status
      };
      state.isUpdateSleepScheduleLoading = false;
      state.updateSleepScheduleError = action.payload?.message;
    })
    .addCase(getLoginActions.success, (state, action) => {
      if (action.payload.person) {
        let personPermissionRoles = action.payload.personPermissionRoles;
        if (state.loggedInPerson && state.loggedInPerson.id === action.payload.person.id) {
          personPermissionRoles = personPermissionRoles || state.loggedInPerson.personPermissionRoles; // keep the existing permissions if they are not returned
        }
        state.loggedInPerson = { ...action.payload.person };
        state.loggedInPerson.personPermissionRoles = personPermissionRoles;
        state.personById[action.payload.person.id] = action.payload.person;
        if (action.payload.personPermissionRoles) {
          for (const personPermissionRole of action.payload.personPermissionRoles) {
            if (personPermissionRole.permissionRole) {
              state.permissionRoleById[personPermissionRole.permissionRoleId] = personPermissionRole.permissionRole;
            }
          }
          state.personById[action.payload.person.id].personPermissionRoles = action.payload.personPermissionRoles.map((ppr) => ({ ...ppr, permissionRole: undefined }));
        }
      }
    })
    .addCase(getRequestRegisterActions.success, (state, action) => {
      if (action.payload.person) {
        state.personById[action.payload.person.id] = action.payload.person;
      }
    })
    .addCase(updatePersonProgramStepActions.start, (state, action) => {
      state.updatePersonProgramLoading = true;
      clearAdminPanelApiErrors(state);
    })
    .addCase(updatePersonProgramStepActions.success, (state, action) => {
      state.updatePersonProgramLoading = false;
      state.success[action.type] = true;
    })
    .addCase(updatePersonProgramStepActions.fail, (state, action) => {
      state.updatePersonProgramLoading = false;
      state.updatePersonProgramError = action.payload?.message;
      state.error[action.type] = {
        message: action.payload?.message,
        status: action.payload.status
      };
    })
    .addCase(clearUpdatePersonProgramAction, (state, action) => {
      state.updatePersonProgramLoading = false;
      state.updatePersonProgramError = null;
      delete state.lastLoadedPersonId;
    })
    .addCase(sendPersonPushNotificationActions.start, (state, action) => {
      state.isSendPersonPushNotificationLoading = true;
      clearAdminPanelApiErrors(state);
    })
    .addCase(sendPersonPushNotificationActions.success, (state, action) => {
      state.isSendPersonPushNotificationLoading = false;
    })
    .addCase(sendPersonPushNotificationActions.fail, (state, action) => {
      state.isSendPersonPushNotificationLoading = false;
      state.sendPersonPushNotificationError = action.payload?.message;
    })
    .addCase(sendPushNotificationsActions.start, (state, action) => {
      state.batchLoading = true;
      state.batchSuccess = undefined;
      state.snsError = undefined;
      state.adHocError = undefined;
    })
    .addCase(sendPushNotificationsActions.success, (state, action) => {
      state.snsError = '';
      state.batchLoading = false;
      state.batchSuccess = true;
    })
    .addCase(sendPushNotificationsActions.fail, (state, action) => {
      state.batchLoading = true;
      state.batchSuccess = undefined;
      state.snsError = undefined;
      state.adHocError = undefined;
    })
    .addCase(createAdHocBatchContentActions.start, (state, action) => {
      state.batchLoading = true;
      state.batchSuccess = undefined;
      state.snsError = undefined;
      state.adHocError = undefined;
    })
    .addCase(createAdHocBatchContentActions.success, (state, action) => {
      state.snsError = '';
      state.batchLoading = false;
      state.batchSuccess = true;
    })
    .addCase(createAdHocBatchContentActions.fail, (state, action) => {
      state.batchLoading = true;
      state.batchSuccess = undefined;
      state.snsError = undefined;
      state.adHocError = undefined;
    })
    .addCase(clearPersonResponseStatusAction, (state, action) => {
      state.snsError = undefined;
      state.adHocError = undefined;
      state.batchLoading = false;
      state.batchSuccess = undefined;
      state.updatePersonProgramLoading = false;
      state.updatePersonProgramError = null;
      clearAdminPanelApiErrors(state);
      for (const key in state.isLoading) {
        delete state.isLoading[key];
      }
      for (const key in state.error) {
        delete state.error[key];
      }
      for (const key in state.success) {
        delete state.success[key];
      }
      state.successMessage = undefined;
      state.warningMessage = undefined;
    })
    .addCase(getSleepSchedulesActions.start, (state, action) => {
      state.historicSleepSchedules = undefined;
    })
    .addCase(getSleepSchedulesActions.success, (state, action) => {
      state.historicSleepSchedules = action.payload;
    })
    .addCase(getPersonSettingsActions.success, (state, action) => {
      if (action.payload?.id) {
        state.personSettingById[action.payload.id] = action.payload;
      }
    })
    .addCase(calculateSleepScheduleActions.start, (state, action) => {
      state.currentSleepScheduleCalculation = undefined;
      state.loadingEventChange = true;
    })
    .addCase(calculateSleepScheduleActions.success, (state, action) => {
      state.currentSleepScheduleCalculation = action.payload;
      state.loadingEventChange = false;
    })
    .addCase(calculateSleepScheduleActions.fail, (state, action) => {
      state.error[action.type] = {
        message: action.payload?.message,
        status: action.payload.status
      };
      state.loadingEventChange = false;
    })
    .addCase(saveSleepScheduleAndCalculationsActions.success, (state, action) => {
      const sleepScheduleCalculationResponse: SleepScheduleCalculationResponse = action.payload;
      const sleepScheduleResponse: WorkflowUpdatingResponse<SleepSchedule> = sleepScheduleCalculationResponse.sleepScheduleUpdateResponse;
      const sleepSchedule: SleepSchedule = sleepScheduleResponse?.updatedValue;
      sleepSchedule.sleepScheduleCalculation = {
        ...sleepScheduleCalculationResponse.sleepScheduleCalculation,
        created: sleepScheduleCalculationResponse.sleepScheduleCalculation.created || sleepSchedule.created,
        modifiedDate: sleepScheduleCalculationResponse.sleepScheduleCalculation.modifiedDate || sleepSchedule.modifiedDate
      };

      const historicSleepSchedules: SleepSchedule[] = state.historicSleepSchedules || [];
      if (historicSleepSchedules?.length) {
        historicSleepSchedules[0].endDate = sleepSchedule.startDate;
      }
      historicSleepSchedules.unshift(sleepSchedule);

      if (sleepScheduleCalculationResponse.ignoreWarnings && sleepSchedule.validationWarning) {
        state.warningMessage = sleepSchedule.validationWarning;
      }

      state.historicSleepSchedules = historicSleepSchedules;
      state.loadingEventChange = false;
      state.success[action.type] = true;
    })
    .addCase(clearSleepCalculationAction, (state, action) => {
      clearSleepCalculation(state, action.payload);
    })
    .addCase(getPersonOverviewActions.start, (state, action) => {
      if (action.payload) {
        state.personOverviewList.length = 0;
        state.lastUserOverviewInitialLoad = new Date().getTime();
      }
      state.pageLoading = true;
    })
    .addCase(getPersonOverviewActions.success, (state, action) => {
      action.payload?.map((personOverviewResponse: PersonOverviewResponse) => {
        const personId: string = personOverviewResponse?.person?.id;
        if (!state.personOverviewList.includes(personId)) {
          state.personOverviewList.push(personId);
        }
        setPersonOverview(state, personOverviewResponse);
      });
      state.pageLoading = false;
    })
    .addCase(getPersonOverviewActions.fail, (state, action) => {
      state.error[action.type] = {
        message: action.payload?.message,
        status: action.payload.status
      };
      state.pageLoading = false;
    })
    .addCase(getPersonOverviewCountActions.success, (state, action) => {
      state.personOverviewCount = action.payload;
    })
    .addCase(getPersonOverviewCountActions.fail, (state, action) => {
      state.error[action.type] = {
        message: action.payload?.message,
        status: action.payload.status
      };
    })
    .addCase(getPersonDetailsActions.start, (state, action) => {
      state.selectedPersonDetails = undefined;
    })
    .addCase(getPersonDetailsActions.success, (state, action) => {
      const personDetailsResponse: PersonDetailsResponse = action.payload;
      setPersonDetails(state, personDetailsResponse);
    })
    .addCase(putAccountStatusActions.success, (state, action) => {
      const accountStatusUpdateResponse: WorkflowUpdatingResponse<PersonAccountStatus> = action.payload;
      const personAccountStatus: PersonAccountStatus = accountStatusUpdateResponse.updatedValue;
      if (state.personById?.[accountStatusUpdateResponse.personId]) {
        state.personById[personAccountStatus.personId].accountStatus = personAccountStatus.accountStatus;
      }
      if (state.selectedPersonDetails?.personId === personAccountStatus.personId) {
        const personAccountStatusIds: Nullable<string>[] = state.selectedPersonDetails?.personAccountStatusIds || [];
        const lastActiveIndex: number = personAccountStatusIds.findIndex((x) => x && !!state.personAccountStatusById?.[x]?.id && !state.personAccountStatusById?.[x]?.endDate);
        const personAccountStatusId = personAccountStatusIds[lastActiveIndex];
        if (personAccountStatusId && lastActiveIndex >= 0 && personAccountStatusIds[lastActiveIndex] !== personAccountStatus.id) {
          state.personAccountStatusById[personAccountStatusId].endDate = personAccountStatus.startDate;
        }
        if ((lastActiveIndex < 0 || personAccountStatusIds[lastActiveIndex] !== personAccountStatus.id) && personAccountStatus.id) {
          personAccountStatusIds.unshift(personAccountStatus.id);
        }
        state.selectedPersonDetails.personAccountStatusIds = personAccountStatusIds;
      }
      if (personAccountStatus.id) {
        state.personAccountStatusById[personAccountStatus.id] = personAccountStatus;
      }
      state.loadingEventChange = false;
      state.success[action.type] = true;
    })
    .addCase(putDeviceGroupActions.success, (state, action) => {
      const deviceGroupResponse: WorkflowUpdatingResponse<DeviceGroup> = action.payload;
      const personSettingById: Record<string, PersonSetting> = state.personSettingById;
      const personSetting: PersonSetting = personSettingById?.[deviceGroupResponse.personId];
      if (personSetting) {
        personSetting.deviceGroup = deviceGroupResponse.updatedValue;
      }
      state.loadingEventChange = false;
      state.success[action.type] = true;
    })
    .addCase(putSleepLogDeviceTimesEnabledActions.success, (state, action) => {
      const personId: string = action.payload.personId;
      const sleepLogDeviceTimesEnabledTs: Nullable<string> = action.payload.sleepLogDeviceTimesEnabledTs;
      const personSettingById: Record<string, PersonSetting> = state.personSettingById;
      const personSetting: PersonSetting = personSettingById?.[personId];
      if (personSetting) {
        personSetting.sleepLogDeviceTimesEnabledTs = sleepLogDeviceTimesEnabledTs;
      }
    })
    .addCase(putLunaSleepLogSummariesEnabledActions.success, (state, action) => {
      const personId: string = action.payload.personId;
      const lunaSleepLogSummariesEnabledTs: Nullable<string> = action.payload.lunaSleepLogSummariesEnabledTs;
      const personSettingById: Record<string, PersonSetting> = state.personSettingById;
      const personSetting: PersonSetting = personSettingById?.[personId];
      if (personSetting) {
        personSetting.lunaSleepLogSummariesEnabledTs = lunaSleepLogSummariesEnabledTs;
      }
    })
    .addCase(putLunaSleepScheduleSummariesEnabledActions.success, (state, action) => {
      const personId: string = action.payload.personId;
      const lunaSleepScheduleSummariesEnabledTs: Nullable<string> = action.payload.lunaSleepScheduleSummariesEnabledTs;
      const personSettingById: Record<string, PersonSetting> = state.personSettingById;
      const personSetting: PersonSetting = personSettingById?.[personId];
      if (personSetting) {
        personSetting.lunaSleepScheduleSummariesEnabledTs = lunaSleepScheduleSummariesEnabledTs;
      }
    })
    .addCase(putLunaWeeklySummariesEnabledActions.success, (state, action) => {
      const personId: string = action.payload.personId;
      const lunaWeeklySummariesEnabledTs: Nullable<string> = action.payload.lunaWeeklySummariesEnabledTs;
      const personSettingById: Record<string, PersonSetting> = state.personSettingById;
      const personSetting: PersonSetting = personSettingById?.[personId];
      if (personSetting) {
        personSetting.lunaWeeklySummariesEnabledTs = lunaWeeklySummariesEnabledTs;
      }
    })
    .addCase(putSleepLogAutoSubmissionEnabledActions.success, (state, action) => {
      const personId: string = action.payload.personId;
      const sleepLogAutoSubmissionEnabledTs: Nullable<string> = action.payload.sleepLogAutoSubmissionEnabledTs;
      const personSettingById: Record<string, PersonSetting> = state.personSettingById;
      const personSetting: PersonSetting = personSettingById?.[personId];
      if (personSetting) {
        personSetting.sleepLogAutoSubmissionEnabledTs = sleepLogAutoSubmissionEnabledTs;
      }
    })
    .addCase(putChatBotEnabledActions.success, (state, action) => {
      const personId: string = action.payload.personId;
      const personSettingById: Record<string, PersonSetting> = state.personSettingById;
      const personSetting: PersonSetting = personSettingById?.[personId];
      if (personSetting && action.payload.chatChannelType === ChatChannelType.CHAT_BOT_DEVICE) {
        personSetting.deviceChatBotEnabledTs = action.payload.chatBotEnabledTs;
      } else if (personSetting && action.payload.chatChannelType === ChatChannelType.CHAT_BOT_MOBILE) {
        personSetting.mobileChatBotEnabledTs = action.payload.chatBotEnabledTs;
      } else if (personSetting && action.payload.chatChannelType === ChatChannelType.SLEEP_COACH) {
        personSetting.mobileSleepCoachEnabledTs = action.payload.chatBotEnabledTs;
      }
      state.success[action.type] = true;
    })
    .addCase(putMobileCustomSleepStoriesEnabledActions.success, (state, action) => {
      updatePersonSettings(state, action.payload);
      state.success[action.type] = true;
    })
    .addCase(putSleepScheduleEnabledActions.success, (state, action) => {
      updatePersonSettings(state, action.payload);
      state.success[action.type] = true;
    })
    .addCase(putShowSleepFeaturesActions.success, (state, action) => {
      updatePersonSettings(state, action.payload);
      state.success[action.type] = true;
    })
    .addCase(putEnableDeviceTrainingActions.success, (state, action) => {
      updatePersonSettings(state, action.payload);
      state.success[action.type] = true;
    })
    .addCase(userPermissionsActions.start, (state, action) => {
      state.isLoadingUserPermissions = true;
      state.userPermissions = undefined;
    })
    .addCase(userPermissionsActions.success, (state, action) => {
      state.isLoadingUserPermissions = false;
      state.userPermissions = action.payload;
    })
    .addCase(userPermissionsActions.fail, (state, action) => {
      state.isLoadingUserPermissions = false;
    })
    .addCase(getValidSleepSchedulesActions.start, (state, action) => {
      state.kokoMapSleepScheduleIds = [];
    })
    .addCase(getValidSleepSchedulesActions.success, (state, action) => {
      action.payload?.forEach((sleepSchedule) => {
        state.kokoMapSleepScheduleIds.push(sleepSchedule.id);
        state.sleepScheduleById[sleepSchedule.id] = sleepSchedule;
      });
    })
    .addCase(saveIsiScoreActions.success, (state, action) => {
      const upsertedIsiScore: PersonIsiScore = action.payload;
      if (upsertedIsiScore.id) {
        state.personIsiScoreById[upsertedIsiScore.id] = upsertedIsiScore;
      }

      const selectedPersonDetails: Nullable<PersonDetails> = state.selectedPersonDetails;
      if (selectedPersonDetails?.personId === upsertedIsiScore?.personId) {
        const isiScoresIds: Nullable<string>[] = selectedPersonDetails?.personIsiScoreIds || [];
        if (!isiScoresIds.some((sc) => sc === upsertedIsiScore.id) && upsertedIsiScore.id) {
          isiScoresIds.push(upsertedIsiScore.id);
        }
        isiScoresIds.sort((a, b) => {
          if (a && b) {
            const isiScoreA: PersonIsiScore = state.personIsiScoreById[a];
            const isiScoreB: PersonIsiScore = state.personIsiScoreById[b];
            return isiScoreB.isiTs.localeCompare(isiScoreA.isiTs);
          }
          return 0;
        });
        selectedPersonDetails.personIsiScoreIds = isiScoresIds;
        state.selectedPersonDetails = selectedPersonDetails;
      }
      state.success[action.type] = true;
    })
    .addCase(saveOrderActions.success, (state, action) => {
      const order: PersonOrder = action.payload;
      const orderId: Nullable<string> = order?.id;
      const personId: string = order?.personId;
      if (personId) {
        if (state.selectedPersonDetails?.personId === personId) {
          state.selectedPersonDetails.latestPersonOrderId = orderId;
        }
        if (state.personOverviewById?.[personId]) {
          state.personOverviewById[personId].latestPersonOrderId = orderId;
        }
      }
      if (orderId) {
        const personOrderById: Record<string, PersonOrder> = state.personOrderById;
        personOrderById[orderId] = order;
      }
      state.success[action.type] = true;
    })
    .addCase(saveShipmentActions.success, (state, action) => {
      const deviceShipment: DeviceShipment = action.payload;
      const deviceShipmentId: string = deviceShipment?.id;
      if (deviceShipmentId) {
        const deviceShipmentById: Record<string, DeviceShipment> = state.deviceShipmentById;
        deviceShipmentById[deviceShipmentId] = deviceShipment;
        const personId: string = deviceShipment?.personId;
        if (personId) {
          if (state.selectedPersonDetails?.personId === personId && state.selectedPersonDetails?.latestDeviceShipmentId) {
            const selectedPersonShipment: DeviceShipment = deviceShipmentById?.[state.selectedPersonDetails?.latestDeviceShipmentId];
            if (!selectedPersonShipment) {
              state.selectedPersonDetails.latestDeviceShipmentId = deviceShipment.id;
            } else {
              const sortedShipments: DeviceShipment[] = [deviceShipment, selectedPersonShipment];
              sortedShipments.sort(deviceShipmentSorter);
              state.selectedPersonDetails.latestDeviceShipmentId = sortedShipments?.[0]?.id;
            }
            const deviceShipmentIds: string[] = state.selectedPersonDetails?.deviceShipmentIds || [];
            if (!deviceShipmentIds?.some((x) => x === deviceShipmentId)) {
              deviceShipmentIds.push(deviceShipmentId);
            }
            state.selectedPersonDetails.deviceShipmentIds =
              deviceShipmentIds
                .map((x) => deviceShipmentById?.[x])
                .filter((x) => !!x)
                .sort(deviceShipmentSorter)
                .map((x) => x.id) || [];
          }
          const latestDeviceShipmentId: Nullable<string> = state.personOverviewById?.[personId]?.latestDeviceShipmentId;
          if (latestDeviceShipmentId) {
            const selectedPersonShipment: DeviceShipment = deviceShipmentById?.[latestDeviceShipmentId];
            if (!selectedPersonShipment) {
              state.personOverviewById[personId].latestDeviceShipmentId = deviceShipment.id;
            } else {
              const sortedShipments: DeviceShipment[] = [deviceShipment, selectedPersonShipment];
              sortedShipments.sort(deviceShipmentSorter);
              state.personOverviewById[personId].latestDeviceShipmentId = sortedShipments?.[0]?.id;
            }
          }
        }
      }
      state.success[action.type] = true;
    })
    .addCase(putEsIdActions.success, (state, action) => {
      const personId: string = action.payload?.personId;
      const esId = action.payload?.esId;
      if (personId) {
        const personById: Record<string, Person> = state.personById;
        const person: Person = personById?.[personId];
        if (person) {
          person.esId = esId;
          personById[personId] = person;
        }
      }
      state.success[action.type] = true;
    })
    .addCase(postUnlinkDeviceActions.success, (state, action) => {
      const personId: string = action.payload?.personId;
      if (personId) {
        if (state.personOverviewById?.[personId]?.deviceId) {
          state.personOverviewById[personId].deviceId = undefined;
        }
      }
      state.success[action.type] = true;
    })
    .addCase(getPersonPermissionsByEmailActions.success, (state, action) => {
      const personPermissionResponse: PersonPermissionsResponse = action.payload;
      const person: Person = personPermissionResponse?.person;
      if (person?.id) {
        state.personById[person.id] = person;
        state.personPermissionsById[person.id] = {
          personId: person.id,
          agent: personPermissionResponse.agent,
          contentEditor: personPermissionResponse.contentEditor,
          operational: personPermissionResponse.operational,
          piiAccess: personPermissionResponse.piiAccess,
          admin: personPermissionResponse.admin,
          hasExpiredPermissions: personPermissionResponse.hasExpiredPermissions
        };
      }
      state.successMessage = `User ${person.email} temporarily added to result set`;
    })
    .addCase(getPersonPermissionsActions.success, (state, action) => {
      const personPermissionResponses: PersonPermissionsResponse[] = action.payload;
      personPermissionResponses.forEach((personPermissionResponse) => {
        const person: Person = personPermissionResponse?.person;
        if (person?.id) {
          state.personById[person.id] = person;
          state.personPermissionsById[person.id] = {
            personId: person.id,
            agent: personPermissionResponse.agent,
            contentEditor: personPermissionResponse.contentEditor,
            operational: personPermissionResponse.operational,
            piiAccess: personPermissionResponse.piiAccess,
            admin: personPermissionResponse.admin,
            hasExpiredPermissions: personPermissionResponse.hasExpiredPermissions
          };
        }
      });
      state.success[action.type] = true;
    })
    // .addCase(permissionRoleUpsertActions.success, (state, action) => {
    //   const personPermissionRole: PersonPermissionRole = action.payload?.personPermissionRole;
    //   const permissionRoleTypeField: string = action.payload?.permissionRoleTypeField;
    //   const personId: string = personPermissionRole?.personId;
    //   const personPermissions: PersonActivePermissions = state.personPermissionsById[personId];
    //   if (personPermissionRole.endTs) {
    //     personPermissions[permissionRoleTypeField] = undefined;
    //     personPermissions.hasExpiredPermissions = true;
    //   } else {
    //     personPermissions[permissionRoleTypeField] = personPermissionRole;
    //   }
    //   state.success[action.type] = true;
    // })
    .addCase(getDemoAccessCodesAction.success, (state, action) => {
      Object.keys(action.payload.personById).forEach((key) => {
        const person: Person = action.payload.personById[key];
        state.personById[person.id] = {
          ...state.personById[person.id],
          ...person
        };
      });
    })
    .addCase(getSleepSchedulesForReviewActions.success, (state, action) => {
      if (action.payload && action.payload.length > 0) {
        action.payload.forEach((sleepSchedule: SleepSchedule) => {
          if (sleepSchedule.person) {
            state.personById[sleepSchedule.person.id] = sleepSchedule.person;
          }
        });
      }
    })
    .addCase(getCareTeamPersonsAction.success, (state, action) => {
      action.payload?.forEach((person) => {
        addPersonToState(state, person);
      });
    })
    .addCase(getCareTeamsAction.success, (state, action) => {
      action.payload?.forEach((careTeam) => {
        processCareTeamResponse(state, careTeam);
      });
    })
    .addCase(getPersonAndPermissionsByOrgIdAction.start, (state, action) => {
      state.isLoading[getActionStringWithoutState(action.type)] = true;
    })
    .addCase(getPersonAndPermissionsByOrgIdAction.success, (state, action) => {
      action.payload?.forEach((person) => {
        addPersonToState(state, person);
      });
    })
    .addCase(getPersonAndPermissionsByOrgIdAction.fail, (state, action) => {
      state.error[getActionStringWithoutState(action.type)] = {
        message: action.payload?.message,
        status: action.payload.status
      };
    })
    .addCase(removePersonPermissionRoleActions.start, (state, action) => {
      state.isLoading[getActionStringWithoutState(action.type)] = true;
    })
    .addCase(removePersonPermissionRoleActions.success, (state, action) => {
      state.isLoading[getActionStringWithoutState(action.type)] = false;
      const personPermissionRole: PersonPermissionRole = action.payload;
      if (personPermissionRole) {
        const personId: string = personPermissionRole?.personId;
        if (state.personPermissionRoleIdsByPersonId[personId]) {
          state.personPermissionRoleIdsByPersonId[personId] = state.personPermissionRoleIdsByPersonId[personId].filter((id) => id !== personPermissionRole.id);
        }
        delete state.personPermissionRoleById[personPermissionRole.id];
      }
    })
    .addCase(removePersonPermissionRoleActions.fail, defaultActionFail)
    .addCase(putPersonPermissionRoleActions.start, defaultActionStart)
    .addCase(putPersonPermissionRoleActions.success, (state, action) => {
      state.isLoading[getActionStringWithoutState(action.type)] = false;
      // add personPermissionRole to state
      const personPermissionRole: PersonPermissionRole = action.payload;
      if (personPermissionRole) {
        const personId: string = personPermissionRole?.personId;
        if (!state.personPermissionRoleIdsByPersonId[personId]) {
          state.personPermissionRoleIdsByPersonId[personId] = [];
        }
        state.personPermissionRoleIdsByPersonId[personId].push(personPermissionRole.id);
        state.personPermissionRoleById[personPermissionRole.id] = personPermissionRole;
      }
    })
    .addCase(putPersonPermissionRoleActions.fail, defaultActionFail)
    .addCase(getAllPermissionRolesActions.start, defaultActionStart)
    .addCase(getAllPermissionRolesActions.success, (state, action) => {
      action.payload?.forEach((permissionRole) => {
        state.permissionRoleById[permissionRole.id] = permissionRole;
        if (permissionRole?.permissionRoleType) {
          state.permissionRoleTypeById[permissionRole.permissionRoleTypeId] = permissionRole.permissionRoleType;
        }
      });
    })
    .addCase(getAllPermissionRolesActions.fail, defaultActionFail)
    .addCase(getPersonVitalsAction.start, defaultActionStart)
    .addCase(getPersonVitalsAction.success, (state, action) => {
      const personVitalsTag = getActionStringWithoutState(action.type);
      state.isLoading[personVitalsTag] = false;
      const personVitals = action.payload ?? [];
      personVitals.forEach((personVital) => {
        state.personVitalsByPersonId[personVital.personId] = personVital;
      });
    })
    .addCase(getPersonVitalsAction.fail, defaultActionFail)
    .addCase(setSelectedPatientIdAction, (state, action) => {
      state.selectedPatientId = action.payload;
    })
    .addCase(getPersonVitalInsightsAction.start, defaultActionStart)
    .addCase(getPersonVitalInsightsAction.success, (state, action) => {
      const personVitalInsightsTag = getActionStringWithoutState(action.type);
      state.isLoading[personVitalInsightsTag] = false;
      state.selectedPersonVitalInsights = action.payload;
    })
    .addCase(getPersonVitalInsightsAction.fail, defaultActionFail)
    .addCase(getPersonVitalOverviewAction.start, defaultActionStart)
    .addCase(getPersonVitalOverviewAction.success, (state, action) => {
      const personVitalOverviewTag = getActionStringWithoutState(action.type);
      state.isLoading[personVitalOverviewTag] = false;
      state.selectedPersonVitalOverview = action.payload;
    })
    .addCase(getPersonVitalOverviewAction.fail, defaultActionFail)
    .addCase(deletePersonAction.fail, defaultActionFail)
    .addCase(updatePatientAction.start, defaultActionStart)
    .addCase(updatePatientAction.success, (state, action) => {
      const updatePatientTag = getActionStringWithoutState(action.type);
      const person: Person = action.payload;
      person.lastUpdated = new Date().getTime();
      state.personById[person.id] = person;
      state.isLoading[updatePatientTag] = false;
    })
    .addCase(updatePatientAction.fail, defaultActionFail);

  builder
    .addMatcher(
      // matcher can be defined inline as a type predicate function
      (action): any => action.type.endsWith('-start') && action.type.startsWith(PERSON_ACTION_PREFIX),
      (state, action) => {
        setDefaultStart(state, action.type);
      }
    )
    .addMatcher(
      // matcher can be defined inline as a type predicate function
      (action): any => action.type.endsWith('-success') && action.type.startsWith(PERSON_ACTION_PREFIX),
      (state, action) => {
        const tag = getActionStringWithoutState(action.type);
        state.isLoading[tag] = false;
      }
    )
    .addMatcher(
      // matcher can be defined inline as a type predicate function
      (action): any => action.type.endsWith('-fail') && action.type.startsWith(PERSON_ACTION_PREFIX),
      (state, action) => {
        setDefaultFail(state, action);
      }
    );
});

const updatePersonSettings = (state: PersonState, personSetting: PersonSetting): void => {
  const personId: string = personSetting?.id;
  if (personId) {
    state.personSettingById[personId] = personSetting;
  }
};

const setPersonDetails = (state: PersonState, personDetailsResponse: PersonDetailsResponse): void => {
  const personOverview: PersonOverview = setPersonOverview(state, personDetailsResponse);
  const selectedPersonDetails: PersonDetails = {
    ...personOverview,
    latestWindDownProgramId: personDetailsResponse?.latestWindDownProgram?.id,
    latestBedResetProgramId: personDetailsResponse?.latestBedResetProgram?.id,
    personIsiScoreIds: personDetailsResponse?.personIsiScores?.map((p) => p.id),
    sensorPersonIds: personDetailsResponse?.sensorPersons?.map((p) => p.id),
    sensorDeviceIds: personDetailsResponse.sensorDevices?.map((p) => p.id),
    deviceIds: personDetailsResponse.devices?.map((p) => p.id),
    locationIds: personDetailsResponse.locations?.map((p) => p.id),
    deviceShipmentIds: personDetailsResponse.deviceShipments?.map((p) => p.id),
    personAccountStatusIds: personDetailsResponse.personAccountStatuses?.map((p) => p.id),
    deviceTrainingIds: personDetailsResponse.deviceTrainings?.map((p) => p.id)
  };
  state.selectedPersonDetails = selectedPersonDetails;
  if (selectedPersonDetails.latestWindDownProgramId && personDetailsResponse.latestWindDownProgram) {
    state.personSleepProgramById[selectedPersonDetails.latestWindDownProgramId] = personDetailsResponse.latestWindDownProgram;
  }
  if (selectedPersonDetails.latestBedResetProgramId && personDetailsResponse.latestBedResetProgram) {
    state.personSleepProgramById[selectedPersonDetails.latestBedResetProgramId] = personDetailsResponse.latestBedResetProgram;
  }
  const personIsiScores: PersonIsiScore[] = personDetailsResponse.personIsiScores || [];
  personIsiScores?.forEach((personIsiScore) => {
    if (personIsiScore.id) {
      state.personIsiScoreById[personIsiScore.id] = personIsiScore;
    }
  });
  const sensorPersons: SensorPerson[] = personDetailsResponse?.sensorPersons || [];
  sensorPersons?.forEach((sensorPerson) => {
    state.sensorPersonById[sensorPerson.id] = sensorPerson;
  });
  const deviceShipments: DeviceShipment[] = personDetailsResponse?.deviceShipments || [];
  deviceShipments?.forEach((deviceShipment) => {
    state.deviceShipmentById[deviceShipment.id] = deviceShipment;
  });
  const personAccountStatuses: PersonAccountStatus[] = personDetailsResponse?.personAccountStatuses || [];
  personAccountStatuses?.forEach((personAccountStatus) => {
    if (personAccountStatus.id) {
      state.personAccountStatusById[personAccountStatus.id] = personAccountStatus;
    }
  });
  const deviceTrainings: DeviceTraining[] = personDetailsResponse?.deviceTrainings || [];
  deviceTrainings?.forEach((deviceTraining) => {
    if (deviceTraining.id) {
      state.deviceTrainingById[deviceTraining.id] = deviceTraining;
    }
  });
};

const setPersonOverview = (state: PersonState, personOverviewResponse: PersonOverviewResponse): PersonOverview => {
  const personId: string = personOverviewResponse?.person?.id;
  const personOverview: PersonOverview = {
    personId: personId,
    sensorPersonId: personOverviewResponse?.sensorPerson?.id,
    sensorDeviceId: personOverviewResponse?.sensorDevice?.id,
    deviceId: personOverviewResponse?.device?.id,
    locationId: personOverviewResponse?.location?.id,
    personSleepProgramId: personOverviewResponse?.personProgram?.id,
    clientDevicePushInfoId: personOverviewResponse?.clientDevicePushInfo?.id,
    latestPersonOrderId: personOverviewResponse?.latestPersonOrder?.id,
    latestDeviceShipmentId: personOverviewResponse?.latestDeviceShipment?.id,
    latestClientDeviceId: personOverviewResponse?.latestClientDevice?.id
  };
  state.personOverviewById[personId] = personOverview;
  const permissionRoles = personOverviewResponse?.person?.personPermissionRoles || state.personById[personId]?.personPermissionRoles;
  state.personById[personId] = personOverviewResponse?.person;
  state.personById[personId].personPermissionRoles = permissionRoles; // prevent overwriting permssionRoles for auth person
  state.personSettingById[personId] = personOverviewResponse?.personSetting;
  if (personOverview.sensorPersonId && personOverviewResponse.sensorPerson) {
    state.sensorPersonById[personOverview.sensorPersonId] = personOverviewResponse.sensorPerson;
  }
  if (personOverview.personSleepProgramId && personOverviewResponse.personProgram) {
    state.personSleepProgramById[personOverview.personSleepProgramId] = personOverviewResponse.personProgram;
  }
  if (personOverview.clientDevicePushInfoId && personOverviewResponse.clientDevicePushInfo) {
    state.clientDevicePushInfoById[personOverview.clientDevicePushInfoId] = personOverviewResponse.clientDevicePushInfo;
  }
  if (personOverview.latestPersonOrderId && personOverviewResponse.latestPersonOrder) {
    state.personOrderById[personOverview.latestPersonOrderId] = personOverviewResponse.latestPersonOrder;
  }
  if (personOverview.latestDeviceShipmentId && personOverviewResponse.latestDeviceShipment) {
    state.deviceShipmentById[personOverview.latestDeviceShipmentId] = personOverviewResponse.latestDeviceShipment;
  }
  if (personOverview.latestClientDeviceId && personOverviewResponse.latestClientDevice) {
    state.clientDeviceById[personOverview.latestClientDeviceId] = personOverviewResponse.latestClientDevice;
  }
  return personOverview;
};

const setDefaultStart = (state: PersonState, tag: string): void => {
  state.isLoading[getActionStringWithoutState(tag)] = true;
  delete state.error[tag];
  delete state.success[tag];
};

const setDefaultFail = (state: PersonState, action: ReduxAction): void => {
  delete state.isLoading[getActionStringWithoutState(action.type)];
  state.error[action.type] = {
    message: action.payload?.message,
    status: action.payload.status
  };
  state.success[action.type] = false;
};

const clearAdminPanelApiErrors = (state: PersonState): void => {
  state.sendPersonPushNotificationError = undefined;
  state.personSettingProgramError = undefined;
  state.updatePersonProgramError = undefined;
  state.updateSleepScheduleError = undefined;
};

const clearSleepCalculation = (state: PersonState, clearHistoric?: Nullable<boolean>): void => {
  if (clearHistoric) {
    state.historicSleepSchedules = undefined;
  }
  clearCurrentSleepCalculation(state);
};

const clearCurrentSleepCalculation = (state: PersonState): void => {
  state.currentSleepScheduleCalculation = undefined;
};

const addPersonToState = (state: PersonState, person: Person): void => {
  if (person.personPermissionRoles) {
    for (const personPermissionRole of person.personPermissionRoles) {
      if (personPermissionRole.permissionRole) {
        state.permissionRoleById[personPermissionRole.permissionRoleId] = personPermissionRole.permissionRole;
        if (personPermissionRole.permissionRole?.permissionRoleType) {
          state.permissionRoleTypeById[personPermissionRole.permissionRole.permissionRoleType.id] = personPermissionRole.permissionRole.permissionRoleType;
          personPermissionRole.permissionRole.permissionRoleType = undefined;
        }
      }
      if (!state.personPermissionRoleIdsByPersonId[person.id]) {
        state.personPermissionRoleIdsByPersonId[person.id] = [];
      }
      if (state.personPermissionRoleIdsByPersonId[person.id].indexOf(personPermissionRole.id) < 0) {
        state.personPermissionRoleIdsByPersonId[person.id].push(personPermissionRole.id);
      }
      state.personPermissionRoleById[personPermissionRole.id] = personPermissionRole;
    }
  }
  state.personById[person.id] = person;
};

const processCareTeamResponse = (state: PersonState, careTeam: CareTeam): void => {
  careTeam?.members?.forEach((member) => {
    if (member.person) {
      addPersonToState(state, member.person);
    }
  });
};
