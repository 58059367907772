import React from 'react';
import { Button, Menu, MenuItem } from '@mui/material';
import style from './DropDownMenu.scss';
import classNames from 'classnames';
import { KeyboardArrowDown, KeyboardArrowUp } from '@material-ui/icons';
import { Color } from 'utils/color';

export type DropDownMenuProps = {
  menuText?: string;
  customButtonClass?: string;
  additionalButtonClass?: string;
  customMenuElement?: (onMenuClick: (event) => void) => React.JSX.Element;
  menuItems: MenuItemType[];
};

export type MenuItemType = MenuItemProps | false;

export type MenuItemProps = {
  onClick?: () => void;
  text?: string;
  textClassName?: string;
  iconImgSrc?: string;
  customMenuItem?: () => React.JSX.Element;
};

export const DropDownMenu: React.FC<DropDownMenuProps> = React.memo(({ menuText, customMenuElement, customButtonClass, additionalButtonClass, menuItems = [] }) => {
  const [menuAnchorElement, setMenuAnchorElement] = React.useState(null);
  const isMenuOpen = Boolean(menuAnchorElement);

  const onMenuClick = (event) => {
    setMenuAnchorElement(event.currentTarget);
  };

  const onMenuClose = () => {
    setMenuAnchorElement(null);
  };
  const filteredMenuItems = menuItems.filter(Boolean) as MenuItemProps[];

  const getMenuItem = (menuItem: MenuItemProps, index): React.JSX.Element => {
    let menuItemElement;

    if (menuItem.customMenuItem) {
      menuItemElement = menuItem.customMenuItem();
    } else if (menuItem.iconImgSrc) {
      menuItemElement = (
        <>
          <img src={menuItem.iconImgSrc} className={style.menuItemIcon} alt="menu" />
          <div className={classNames(style.menuItemText, menuItem.textClassName)}>{menuItem.text}</div>
        </>
      );
    } else {
      menuItemElement = (
        <div className={classNames(style.menuItemText, menuItem.textClassName)} key={menuItem.text}>
          {menuItem.text}
        </div>
      );
    }

    return (
      <MenuItem
        key={index}
        onClick={() => {
          menuItem.onClick?.();
          onMenuClose();
        }}
      >
        {menuItemElement}
      </MenuItem>
    );
  };

  return (
    <>
      {!customMenuElement && (
        <Button
          className={classNames(customButtonClass || [style.button, additionalButtonClass], isMenuOpen ? style.active : {})}
          onClick={onMenuClick}
          endIcon={isMenuOpen ? <KeyboardArrowUp htmlColor={Color.DARK_BLUE_NEW} /> : <KeyboardArrowDown htmlColor={Color.DARK_BLUE_NEW} />}
        >
          {menuText}
        </Button>
      )}
      {customMenuElement && customMenuElement(onMenuClick)}
      <Menu anchorEl={menuAnchorElement} open={isMenuOpen} onClose={onMenuClose} className={style.menu}>
        {filteredMenuItems.map((menuItem, index) => getMenuItem(menuItem, index))}
      </Menu>
    </>
  );
});
