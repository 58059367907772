import React from 'react';
import { SVGBasicType } from 'types/global';
import { Color } from 'utils/color';

export const AlertIcon = ({ width = 20, height = 20, color = Color.RED_600 }: SVGBasicType) => {
  return (
    <svg width={width} height={height} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M17.7586 6.26797L13.732 2.24141C13.4969 2.0077 13.1792 1.87605 12.8477 1.875H7.15234C6.82083 1.87605 6.50309 2.0077 6.26797 2.24141L2.24141 6.26797C2.0077 6.50309 1.87605 6.82083 1.875 7.15234V12.8477C1.87605 13.1792 2.0077 13.4969 2.24141 13.732L6.26797 17.7586C6.50309 17.9923 6.82083 18.1239 7.15234 18.125H12.8477C13.1792 18.1239 13.4969 17.9923 13.732 17.7586L17.7586 13.732C17.9923 13.4969 18.1239 13.1792 18.125 12.8477V7.15234C18.1239 6.82083 17.9923 6.50309 17.7586 6.26797ZM9.375 6.25C9.375 6.08424 9.44085 5.92527 9.55806 5.80806C9.67527 5.69085 9.83424 5.625 10 5.625C10.1658 5.625 10.3247 5.69085 10.4419 5.80806C10.5592 5.92527 10.625 6.08424 10.625 6.25V10.625C10.625 10.7908 10.5592 10.9497 10.4419 11.0669C10.3247 11.1842 10.1658 11.25 10 11.25C9.83424 11.25 9.67527 11.1842 9.55806 11.0669C9.44085 10.9497 9.375 10.7908 9.375 10.625V6.25ZM10 14.375C9.81458 14.375 9.63332 14.32 9.47915 14.217C9.32498 14.114 9.20482 13.9676 9.13386 13.7963C9.06291 13.625 9.04434 13.4365 9.08051 13.2546C9.11669 13.0727 9.20598 12.9057 9.33709 12.7746C9.4682 12.6435 9.63525 12.5542 9.8171 12.518C9.99896 12.4818 10.1875 12.5004 10.3588 12.5714C10.5301 12.6423 10.6765 12.7625 10.7795 12.9167C10.8825 13.0708 10.9375 13.2521 10.9375 13.4375C10.9375 13.6861 10.8387 13.9246 10.6629 14.1004C10.4871 14.2762 10.2486 14.375 10 14.375Z"
        fill={color}
      />
    </svg>
  );
};
