import React from 'react';
import NavTopBar from '../../navigation/NavTopBar/NavTopBar';
import style from './NavTopBarContentWrapper.scss';
import { Text } from '../Typography';
import classNames from 'classnames';
import { ThemeProvider } from '@mui/material/styles';
import { mvpTheme } from '../../utils/styles';

interface Props {
  headerText?: string | Function;
  headerClassName?: string;
  contentClassName?: string;
  children: React.ReactNode;
}

const NavTopBarContentWrapper: React.FC<Props> = ({ children, headerText, headerClassName, contentClassName }) => {
  return (
    <ThemeProvider theme={mvpTheme}>
      <div className={style.topContainer}>
        <NavTopBar />
        {headerText && (
          <div className={classNames(style.header, headerClassName)}>
            <div className={style.headerItem}>{typeof headerText === 'function' ? headerText() : <Text variant="h5">{headerText}</Text>}</div>
          </div>
        )}
        <div className={classNames(style.content, contentClassName)}>{children}</div>
      </div>
    </ThemeProvider>
  );
};

export default NavTopBarContentWrapper;
