import React, { useEffect } from 'react';
import { ConnectedProps, connect } from 'react-redux';
import { Button } from '@mui/material';
import { compose } from 'redux';
import { getAuthPersonIdSelector } from '../../redux/oauth/oauthSelectors';
import {
  getPersonByIdSelector,
  getSelectedPatientIdSelector,
  hasAuthUserAdminPermissionSelector,
  hasManageCareTeamPermissionSelector,
  hasPatientAssignPermissionSelector,
  hasPatientClinicalDataInCareTeamPhiReadPermissionSelector,
  hasTeamMemberAccountsCreatePermissionSelector
} from '../../redux/person/personSelector';
import { ReduxState } from '../../redux/types';
import { RouteNames } from '../../utils/navigationUtils';
import style from './NavTopBar.scss';
import { generatePath, useLocation, useNavigate } from 'react-router';
import classNames from 'classnames';
import { getInitials } from 'utils/util';
import { logoutThunk } from '../../redux/oauth/oauthThunks';
import { useAppDispatch } from '../../hooks';
import { DropDownMenu } from '../../components/DropDownMenu/DropDownMenu';
import { getOrganizationByIdSelector } from '../../redux/organization/organizationSelectors';
import { CAIRNS_NAVBAR_LOGO_IMAGE_URL } from 'assets/images/images';
import { getPersonOverviewThunk } from '../../redux/person/personThunks';
import { ComingSoonLabel } from 'containers/ComingSoonLabel/ComingSoonLabel';
import { Text } from 'components/Typography';
import { BellIcon, GearIcon, SignOutIcon } from 'components/Icons';

type PropsFromRedux = ConnectedProps<typeof connectRedux>;

const NavTopBar: React.FC<PropsFromRedux> = ({
  authPersonId,
  personById,
  selectedPatientId,
  hasTeamMemberAccountsCreatePermission,
  hasManageCareTeamPermission,
  hasPatientClinicalDataInCareTeamPhiReadPermission,
  hasAuthUserAdminPermission,
  organizationById,
  getPersonOverview
}) => {
  const authPerson = personById[authPersonId || ''];
  const patientDashboardPath = generatePath(RouteNames.PATIENT_DASHBOARD, { userId: selectedPatientId ?? authPersonId ?? '' });
  const organization = authPerson?.organizationId ? organizationById[authPerson?.organizationId] : undefined;
  const logoImgUrl = organization?.logoImageUrl ?? CAIRNS_NAVBAR_LOGO_IMAGE_URL;
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const personInitials = getInitials(authPerson?.fullName ?? '');

  const isActive = (name: string) => {
    const matched = location.pathname.match(`^/.*${name}/?$`);
    return matched;
  };

  useEffect(() => {
    getPersonOverview({});
  }, []);

  const onCareTeamClick = () => {
    navigate(RouteNames.CARE_TEAM);
  };

  const onNewTeamMemberClick = () => {
    navigate(RouteNames.NEW_TEAM_MEMBER);
  };

  const onSettingsClick = () => {
    navigate(RouteNames.ADMIN);
  };

  const onLogOutClick = () => {
    dispatch(logoutThunk());
  };

  return (
    <div className={style.headerContainer}>
      <div className={style.logoContainer}>
        <a href="/">
          <img
            onError={({ currentTarget }) => {
              currentTarget.onerror = null; // prevents looping
              currentTarget.src = CAIRNS_NAVBAR_LOGO_IMAGE_URL;
            }}
            src={logoImgUrl}
            alt="Logo"
            className={style.logoImage}
          />
        </a>
      </div>

      {/* Dashboard, Patients, and Manage */}
      <div className={style.groupContainer}>
        {hasPatientClinicalDataInCareTeamPhiReadPermission && (
          <div className={style.dashboardTab}>
            <Button disabled className={style.tab} onClick={() => navigate(patientDashboardPath)}>
              Dashboard
            </Button>
            <ComingSoonLabel containerClassName={style.label} />
          </div>
        )}
        {hasPatientClinicalDataInCareTeamPhiReadPermission && (
          <Button className={classNames(style.tab, location.pathname.match(`^/.*patients/?$`) ? style.active : {})} onClick={() => navigate(RouteNames.ALL_PATIENTS)}>
            Patients
          </Button>
        )}
        <DropDownMenu
          menuText="Manage"
          customButtonClass={style.tab}
          menuItems={[
            hasManageCareTeamPermission && {
              onClick: onCareTeamClick,
              text: 'Care team'
            },
            hasTeamMemberAccountsCreatePermission && {
              onClick: onNewTeamMemberClick,
              text: 'New team member'
            }
          ]}
        />
      </div>
      <div className={style.rightMenuContainer}>
        <div className={style.bellContainer}>
          <BellIcon />
        </div>
        <div>
          <DropDownMenu
            customMenuElement={(onMenuClick) => (
              <button className={style.avatar} onClick={onMenuClick}>
                <Text variant="md" className={style.personInitials}>
                  {personInitials}
                </Text>
              </button>
            )}
            menuItems={[
              {
                customMenuItem: () => (
                  <>
                    <div className={style.smallAvatar}>
                      <Text variant="sm" className={style.personInitials}>
                        {personInitials}
                      </Text>
                    </div>
                    <Text variant="md" className={style.menuItemText}>
                      {authPerson?.fullName}
                    </Text>
                  </>
                )
              },
              hasAuthUserAdminPermission && {
                customMenuItem: () => (
                  <div className={style.menuItem} onClick={onSettingsClick} role="button">
                    <GearIcon />
                    <Text variant="md" className={style.menuItemText}>
                      Settings
                    </Text>
                  </div>
                )
              },
              {
                customMenuItem: () => (
                  <div className={style.menuItem} onClick={onLogOutClick} role="button">
                    <SignOutIcon />
                    <Text variant="md" className={style.menuItemText}>
                      Log out
                    </Text>
                  </div>
                )
              }
            ]}
          />
        </div>
      </div>
    </div>
  );
};

const connectRedux = connect(
  (state: ReduxState) => {
    return {
      authPersonId: getAuthPersonIdSelector(state),
      personById: getPersonByIdSelector(state),
      selectedPatientId: getSelectedPatientIdSelector(state),
      hasTeamMemberAccountsCreatePermission: hasTeamMemberAccountsCreatePermissionSelector(state),
      hasManageCareTeamPermission: hasManageCareTeamPermissionSelector(state),
      hasPatientAssignPermission: hasPatientAssignPermissionSelector(state),
      hasPatientClinicalDataInCareTeamPhiReadPermission: hasPatientClinicalDataInCareTeamPhiReadPermissionSelector(state),
      hasAuthUserAdminPermission: hasAuthUserAdminPermissionSelector(state),
      organizationById: getOrganizationByIdSelector(state)
    };
  },
  (dispatch: Function) => ({
    getPersonOverview: getPersonOverviewThunk
  })
);

export default compose(connectRedux)(NavTopBar) as React.ComponentType;
