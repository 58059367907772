import React, { useCallback, useState } from 'react';

import { Person } from 'redux/person/personTypes';
import { PatientDetails } from 'components/PatientDetails/PatientDetails';
import style from './PatientInfoDrawer.scss';
import { Drawer } from '@material-ui/core';
import { Button } from 'components/Button/Button';
import { useNavigate } from 'react-router-dom';
import { DropDownMenu } from 'components/DropDownMenu/DropDownMenu';
import DeletePatient from 'containers/PatientDashboard/components/DeletePatient/DeletePatient';
import { CloseIconButton } from 'components/Button/CloseButton/CloseIconButton';

type ViewPersonProps = {
  person: Person;
  visible: boolean;
  onClose: () => void;
  withButtons?: boolean;
};

export const PatientInfoDrawer: React.FC<ViewPersonProps> = ({ person, visible, onClose, withButtons = false }) => {
  const [showDeletePatientConfirmation, setShowDeletePatientConfirmation] = useState(false);

  const navigate = useNavigate();
  const onPressOpenDashboard = useCallback(() => {
    navigate(`/patients/${person.id}`);
  }, [person]);

  const onEditPatientClick = useCallback(() => {
    navigate(`/patients/${person.id}/editPatient`);
  }, [person]);

  const onDeletePatientClick = () => {
    setShowDeletePatientConfirmation(true);
  };

  return (
    <>
      <Drawer anchor="right" open={visible} onClose={onClose}>
        <div className={style.drawerContainer}>
          <div className={style.drawerHeader}>
            <CloseIconButton onClick={onClose} />
          </div>
          <PatientDetails person={person}>
            {withButtons ? (
              <div className={style.buttonsArea}>
                <DropDownMenu
                  menuText="Actions"
                  menuItems={[
                    {
                      text: 'Edit',
                      onClick: onEditPatientClick
                    },
                    {
                      text: 'Delete',
                      textClassName: style.redText,
                      onClick: onDeletePatientClick
                    }
                  ]}
                />
                <Button className={style.navigationButton} variant="contained" onClick={onPressOpenDashboard}>
                  Open dashboard
                </Button>
              </div>
            ) : null}
          </PatientDetails>
        </div>
      </Drawer>
      <DeletePatient
        patientName={person?.fullName || ''}
        patientId={person?.id}
        onDialogClose={() => setShowDeletePatientConfirmation(false)}
        showDialog={showDeletePatientConfirmation}
      />
    </>
  );
};
