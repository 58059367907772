import { deviceShipmentSorter, isListNotEmpty } from '../../utils/util';
import { ClientDevice, ClientDevicePushInfo } from '../clientDevice/clientDeviceTypes';
import { Error } from '../common/types';
import { getCreateAdContentErrorSelector } from '../content/contentSelectors';
import { selectDeviceByIdSelector, selectLocationByIdSelector, selectSensorDeviceByIdSelector } from '../device/deviceSelectors';
import { Device, DeviceShipment, Location, SensorDevice, ShipmentInfo } from '../device/deviceTypes';
import { getAuthPersonIdSelector } from '../oauth/oauthSelectors';
import { ReduxState } from '../types';
import { deletePersonAction } from './personActions';
import { getActionStringWithoutState } from '../reduxUtils';
import {
  DeviceTraining,
  PERMISSION_ROLE_ID_ADMIN,
  PermissionRole,
  PermissionRoleType,
  Person,
  PersonAccountStatus,
  PersonActivePermissions,
  PersonDetails,
  PersonIsiScore,
  PersonOrder,
  PersonOverview,
  PersonPermissionRole,
  PersonSetting,
  PersonSleepProgram,
  PersonState,
  PersonVitalInsights,
  PersonVitalOverview,
  PersonVitals,
  SensorPerson,
  SleepSchedule,
  SleepScheduleCalculation
} from './personTypes';

const FULLSLEEP_ORG_ID = '00000000-0000-0000-0000-000000000001';
const MDREV_ORG_ID = '00000000-0000-0000-0000-000000000003';

const getPersonState = (state: ReduxState): PersonState => {
  return state.person;
};

export const getPersonByIdSelector = (state: ReduxState): Record<string, Person> => {
  const person: PersonState = getPersonState(state);
  return person.personById;
};

export const getLoggedInPerson = (state: ReduxState): Nullable<Person> => {
  const personState: PersonState = getPersonState(state);
  return personState.loggedInPerson;
};

export const getPersonPermissionsByIdSelector = (state: ReduxState): Record<string, PersonActivePermissions> => {
  const person: PersonState = getPersonState(state);
  return person.personPermissionsById;
};

export const getPersonSettingByIdSelector = (state: ReduxState): Record<string, PersonSetting> => {
  const person: PersonState = getPersonState(state);
  return person.personSettingById;
};

export const getSensorPersonByIdSelector = (state: ReduxState): Record<string, SensorPerson> => {
  const person: PersonState = getPersonState(state);
  return person.sensorPersonById;
};

export const getDevicePersonByPersonIdSelector = (state: ReduxState): Record<string, Device> => {
  const person: PersonState = getPersonState(state);
  return person.devicePersonByPersonId;
};

export const getPersonOrderByIdSelector = (state: ReduxState): Record<string, PersonOrder> => {
  const person: PersonState = getPersonState(state);
  return person.personOrderById;
};

export const getPersonIsiScoreByIdSelector = (state: ReduxState): Record<string, PersonIsiScore> => {
  const person: PersonState = getPersonState(state);
  return person.personIsiScoreById;
};

export const getPersonAccountStatusByIdSelector = (state: ReduxState): Record<string, PersonAccountStatus> => {
  const person: PersonState = getPersonState(state);
  return person.personAccountStatusById;
};

export const getDeviceTrainingByIdSelector = (state: ReduxState): Record<string, DeviceTraining> => {
  const person: PersonState = getPersonState(state);
  return person.deviceTrainingById;
};

export const getPersonSleepProgramByIdSelector = (state: ReduxState): Record<string, PersonSleepProgram> => {
  const person: PersonState = getPersonState(state);
  return person.personSleepProgramById;
};

export const getClientDevicePushInfoByIdSelector = (state: ReduxState): Record<string, ClientDevicePushInfo> => {
  const person: PersonState = getPersonState(state);
  return person.clientDevicePushInfoById;
};

export const getDeviceShipmentByIdSelector = (state: ReduxState): Record<string, DeviceShipment> => {
  const person: PersonState = getPersonState(state);
  return person.deviceShipmentById;
};

export const getClientDeviceById = (state: ReduxState): Record<string, ClientDevice> => {
  const person: PersonState = getPersonState(state);
  return person.clientDeviceById;
};

export const getSleepScheduleByIdSelector = (state: ReduxState): Record<string, SleepSchedule> => {
  const person: PersonState = getPersonState(state);
  return person.sleepScheduleById;
};

export const getPersonFromPersonByIdSelector = (state: ReduxState, personId: string): Person => {
  const person: PersonState = getPersonState(state);
  return person.personById?.[personId];
};

export const getPersonOverviewByIdSelector = (state: ReduxState): Record<string, PersonOverview> => {
  const person: PersonState = getPersonState(state);
  return person.personOverviewById;
};

export const getSelectedPersonAccountStatuses = (state: ReduxState): PersonAccountStatus[] => {
  const selectedPersonDetails: Nullable<PersonDetails> = getSelectedPersonDetailsSelector(state);
  const personAccountStatusById: Record<string, PersonAccountStatus> = getPersonAccountStatusByIdSelector(state);
  const accountStates: PersonAccountStatus[] = [];
  selectedPersonDetails?.personAccountStatusIds?.map((personAccountStatusId) => {
    const personAccountStatus = personAccountStatusId ? personAccountStatusById?.[personAccountStatusId] : undefined;
    if (personAccountStatus) {
      accountStates.push(personAccountStatus);
    }
  });
  return accountStates;
};

export const getSelectedPersonDeviceTrainings = (state: ReduxState): DeviceTraining[] => {
  const selectedPersonDetails: Nullable<PersonDetails> = getSelectedPersonDetailsSelector(state);
  const deviceTrainingById: Record<string, DeviceTraining> = getDeviceTrainingByIdSelector(state);
  const deviceTrainings: DeviceTraining[] = [];
  selectedPersonDetails?.deviceTrainingIds?.map((deviceTrainingId) => {
    const deviceTraining = deviceTrainingId ? deviceTrainingById?.[deviceTrainingId] : undefined;
    if (deviceTraining) {
      deviceTrainings.push(deviceTraining);
    }
  });
  return deviceTrainings;
};

export const getSelectedPersonDeviceShipments = (state: ReduxState): Nullable<DeviceShipment[]> => {
  const selectedPersonDetails: Nullable<PersonDetails> = getSelectedPersonDetailsSelector(state);
  const deviceShipmentById: Record<string, DeviceShipment> = getDeviceShipmentByIdSelector(state);
  return selectedPersonDetails?.deviceShipmentIds
    ?.map((deviceShipmentId) => deviceShipmentById?.[deviceShipmentId])
    ?.filter((deviceShipment) => !!deviceShipment)
    ?.sort(deviceShipmentSorter);
};

export const getSelectedPersonShipmentInfo = (state: ReduxState): Nullable<ShipmentInfo[]> => {
  const selectedPersonDetails: Nullable<PersonDetails> = getSelectedPersonDetailsSelector(state);
  const sensorPersonById: Record<string, SensorPerson> = getSensorPersonByIdSelector(state);
  const sensorDeviceById: Record<string, SensorDevice> = selectSensorDeviceByIdSelector(state);
  const deviceById: Record<string, Device> = selectDeviceByIdSelector(state);
  const locationById: Record<string, Location> = selectLocationByIdSelector(state);
  const deviceSetups: ShipmentInfo[] = [];
  selectedPersonDetails?.sensorPersonIds?.forEach((sensorPersonId) => {
    const sensorPerson: SensorPerson = sensorPersonById?.[sensorPersonId];
    const matchedSensorDevices: SensorDevice[] = [];
    selectedPersonDetails?.sensorDeviceIds?.forEach((sensorDeviceId) => {
      const sensorDevice: SensorDevice = sensorDeviceById[sensorDeviceId];
      if (sensorDevice.sensorId === sensorPerson.sensorId) {
        matchedSensorDevices.push(sensorDevice);
      }
    });
    matchedSensorDevices?.forEach((sensorDevice) => {
      const device: Device = deviceById?.[sensorDevice.deviceId];
      const location: Nullable<Location> = sensorDevice.locationId ? locationById?.[sensorDevice.locationId] : undefined;
      const shipmentInfo: ShipmentInfo = {
        sensorPersonId: sensorPerson.id,
        personId: sensorPerson.personId,
        deviceNumber: device?.deviceNumber,
        installationDate: sensorDevice.startDate,
        removedDate: sensorDevice.endDate,
        timeZone: location?.timeZoneId
      };
      deviceSetups.push(shipmentInfo);
    });
  });
  return deviceSetups.sort((a, b) => (!a.removedDate ? -1 : !b.removedDate ? 1 : b.removedDate.localeCompare(a.removedDate)));
};

export const getLastLoadedPersonSelector = (state: ReduxState): Nullable<Person> => {
  const { lastLoadedPersonId, personById } = getPersonState(state);
  if (lastLoadedPersonId) {
    return personById[lastLoadedPersonId];
  }
  return undefined;
};

export const isPersonSettingProgramLoadingSelector = (state: ReduxState): boolean => {
  const person: PersonState = getPersonState(state);
  return !!person.isPersonSettingProgramLoading;
};

export const getPersonSettingProgramLoadingErrorSelector = (state: ReduxState): Nullable<string> => {
  const person: PersonState = getPersonState(state);
  return person.personSettingProgramError;
};

export const getPersonLoadingEventChangeStateSelector = (state: ReduxState): boolean => {
  const person: PersonState = getPersonState(state);
  return person.loadingEventChange;
};

export const getPersonLoadingStateSelector = (state: ReduxState): Record<string, boolean> => {
  const person: PersonState = getPersonState(state);
  return person.isLoading;
};

export const getPersonErrorStateSelector = (state: ReduxState): Record<string, Error> => {
  const person: PersonState = getPersonState(state);
  return person.error;
};

export const getPersonSuccessStateSelector = (state: ReduxState): Record<string, boolean> => {
  const person: PersonState = getPersonState(state);
  return person.success;
};

export const getPersonSuccessMessageStateSelector = (state: ReduxState): Nullable<string> => {
  const person: PersonState = getPersonState(state);
  return person.successMessage;
};

export const getPersonWarningMessageStateSelector = (state: ReduxState): Nullable<string> => {
  const person: PersonState = getPersonState(state);
  return person.warningMessage;
};

export const isUpdatePersonProgramLoadingSelector = (state: ReduxState): boolean => {
  const person: PersonState = getPersonState(state);
  return !!person.updatePersonProgramLoading;
};

export const getUpdatePersonProgramErrorSelector = (state: ReduxState): string => {
  const person: PersonState = getPersonState(state);
  return person.updatePersonProgramError || '';
};

export const isSendPushNotificationLoadingSelector = (state: ReduxState): boolean => {
  const person: PersonState = getPersonState(state);
  return !!person.isSendPersonPushNotificationLoading;
};

export const getUpdatePersonSettingsErrorSelector = (state: ReduxState): string => {
  const person: PersonState = getPersonState(state);
  return person.updatePersonSettingsError || '';
};

export const isUpdatePersonSettingsLoadingSelector = (state: ReduxState): boolean => {
  const person: PersonState = getPersonState(state);
  return !!person.isUpdatePersonSettingsLoading;
};

export const isUpdateSleepScheduleLoadingSelector = (state: ReduxState): boolean => {
  const person: PersonState = getPersonState(state);
  return !!person.isUpdateSleepScheduleLoading;
};

export const getUpdateSleepScheduleErrorSelector = (state: ReduxState): string => {
  const person: PersonState = getPersonState(state);
  return person.updateSleepScheduleError || '';
};

export const getSendPushNotificationErrorSelector = (state: ReduxState): string => {
  const person: PersonState = getPersonState(state);
  return person.sendPersonPushNotificationError || '';
};

export const getSnsErrorSelector = (state: ReduxState): Nullable<string> => {
  const person: PersonState = getPersonState(state);
  return person.snsError;
};

export const getAdHocErrorSelector = (state: ReduxState): Nullable<string> => {
  const person: PersonState = getPersonState(state);
  return person.adHocError;
};

export const isAdminPanelApiLoadingSelector = (state: ReduxState): boolean => {
  return (
    isUpdatePersonProgramLoadingSelector(state) ||
    isPersonSettingProgramLoadingSelector(state) ||
    isSendPushNotificationLoadingSelector(state) ||
    isUpdatePersonSettingsLoadingSelector(state) ||
    isUpdateSleepScheduleLoadingSelector(state)
  );
};

export const getAdminPanelApiErrorSelector = (state: ReduxState): Nullable<string> => {
  const creatingAdHocContentError = getCreateAdContentErrorSelector(state);
  return (
    getUpdatePersonProgramErrorSelector(state) ||
    getUpdatePersonSettingsErrorSelector(state) ||
    getUpdateSleepScheduleErrorSelector(state) ||
    getSendPushNotificationErrorSelector(state) ||
    getPersonSettingProgramLoadingErrorSelector(state) ||
    creatingAdHocContentError
  );
};

export const getHistoricSleepSchedulesSelector = (state: ReduxState): Nullable<SleepSchedule[]> => {
  const person: PersonState = getPersonState(state);
  return person.historicSleepSchedules;
};

export const getCurrentSleepScheduleCalculationSelector = (state: ReduxState): Nullable<SleepScheduleCalculation> => {
  const person: PersonState = getPersonState(state);
  return person.currentSleepScheduleCalculation;
};

export const getPersonOverviewListSelector = (state: ReduxState): Array<string> => {
  const person: PersonState = getPersonState(state);
  return person.personOverviewList;
};

export const getSelectedPersonDetailsSelector = (state: ReduxState): Nullable<PersonDetails> => {
  const person: PersonState = getPersonState(state);
  return person.selectedPersonDetails;
};

export const getSelectedPersonVitalInsights = (state: ReduxState): Nullable<PersonVitalInsights> => {
  const person: PersonState = getPersonState(state);
  return person.selectedPersonVitalInsights;
};

export const getSelectedPersonVitalOverview = (state: ReduxState): Nullable<PersonVitalOverview> => {
  const person: PersonState = getPersonState(state);
  return person.selectedPersonVitalOverview;
};

export const getPersonOverviewCountSelector = (state: ReduxState): number => {
  const person: PersonState = getPersonState(state);
  return person.personOverviewCount;
};

export const getBatchLoadingSelector = (state: ReduxState): boolean => {
  const person: PersonState = getPersonState(state);
  return person.batchLoading;
};

export const getBatchSuccessSelector = (state: ReduxState): Nullable<boolean> => {
  const person: PersonState = getPersonState(state);
  return person.batchSuccess;
};

export const getPageLoadingSelector = (state: ReduxState): boolean => {
  const person: PersonState = getPersonState(state);
  return person.pageLoading;
};

export const getUserPermissionsSelector = (state: ReduxState): Nullable<PermissionRole[]> => {
  const person: PersonState = getPersonState(state);
  return person.userPermissions;
};

export const getKokoMapSleepSchedulesSelector = (state: ReduxState): SleepSchedule[] => {
  const person: PersonState = getPersonState(state);
  const sleepScheduleById = getSleepScheduleByIdSelector(state);
  const sleepSchedules: SleepSchedule[] = [];
  person.kokoMapSleepScheduleIds?.forEach((ssId: string) => {
    sleepSchedules.push(sleepScheduleById[ssId]);
  });
  return sleepSchedules;
};

export const getSelectedPatientIdSelector = (state: ReduxState): Nullable<string> => {
  const person: PersonState = getPersonState(state);
  return person.selectedPatientId;
};

export const isLoadingUserPermissionsSelector = (state: ReduxState): boolean => {
  const person: PersonState = getPersonState(state);
  return !!person.isLoadingUserPermissions;
};

export const isLoadingPostNewPatientSelector = (state: ReduxState): boolean => {
  const person: PersonState = getPersonState(state);
  return !!person.isLoadingPostNewPatient;
};

export const getErrorPostNewPatientSelector = (state: ReduxState): Error | undefined => {
  const person: PersonState = getPersonState(state);
  return person.errorPostNewPatient;
};

export const isLoadingPostNewTeamMemberSelector = (state: ReduxState): boolean => {
  const person: PersonState = getPersonState(state);
  return !!person.isLoadingPostNewTeamMember;
};

export const getErrorPostNewTeamMemberSelector = (state: ReduxState): Error | undefined => {
  const person: PersonState = getPersonState(state);
  return person.errorPostNewTeamMember;
};

export const getIsNewDashboardUserSelector = (state: ReduxState): boolean => {
  const authPersonId = getAuthPersonIdSelector(state);
  const personState: PersonState = getPersonState(state);
  if (authPersonId) {
    const person = personState.personById[authPersonId];
    return person && person.organizationId !== FULLSLEEP_ORG_ID;
  }
  return false;
};

export const getIsMdRevUserSelector = (state: ReduxState): boolean => {
  const authPersonId = getAuthPersonIdSelector(state);
  const personState: PersonState = getPersonState(state);
  if (authPersonId) {
    const person = personState.personById[authPersonId];
    return person && person.organizationId === MDREV_ORG_ID;
  }
  return false;
};

export const hasPatientCreatePermissionSelector = (state: ReduxState): boolean => {
  const person = getLoggedInPerson(state);
  if (person && person.personPermissionRoles && isListNotEmpty(person.personPermissionRoles)) {
    for (const personPermissionRole of person.personPermissionRoles) {
      if (personPermissionRole.permissionRole?.patientAccountsCreateDisable) {
        return true;
      }
    }
  }
  return false;
};

export const hasClinicalAccountsCreatePermissionSelector = (state: ReduxState): boolean => {
  const person = getLoggedInPerson(state);
  if (person && person.personPermissionRoles && isListNotEmpty(person.personPermissionRoles)) {
    for (const personPermissionRole of person.personPermissionRoles) {
      if (personPermissionRole.permissionRole?.clinicalAccountsCreateDisable) {
        return true;
      }
    }
  }
  return false;
};

export const hasNonClinicalAccountsCreatePermissionSelector = (state: ReduxState): boolean => {
  const person = getLoggedInPerson(state);
  if (person && person.personPermissionRoles && isListNotEmpty(person.personPermissionRoles)) {
    for (const personPermissionRole of person.personPermissionRoles) {
      if (personPermissionRole.permissionRole?.nonClinicalAccountsCreateDisable) {
        return true;
      }
    }
  }
  return false;
};

export const hasOrgAdminAccountsCreatePermissionSelector = (state: ReduxState): boolean => {
  const person = getLoggedInPerson(state);
  if (person && person.personPermissionRoles && isListNotEmpty(person.personPermissionRoles)) {
    for (const personPermissionRole of person.personPermissionRoles) {
      if (personPermissionRole.permissionRole?.orgAdminAccountsCreateDisable) {
        return true;
      }
    }
  }
  return false;
};

export const hasCreateOrgPermissionSelector = (state: ReduxState): boolean => {
  const person = getLoggedInPerson(state);
  if (person && person.personPermissionRoles && isListNotEmpty(person.personPermissionRoles)) {
    for (const personPermissionRole of person.personPermissionRoles) {
      if (personPermissionRole.permissionRole?.orgCreateDisable) {
        return true;
      }
    }
  }
  return false;
};

export const hasManageCareTeamPermissionSelector = (state: ReduxState): boolean => {
  const person = getLoggedInPerson(state);
  if (person && person.personPermissionRoles && isListNotEmpty(person.personPermissionRoles)) {
    for (const personPermissionRole of person.personPermissionRoles) {
      if (personPermissionRole.permissionRole?.manageCareTeams) {
        return true;
      }
    }
  }
  return false;
};

export const hasPatientAssignPermissionSelector = (state: ReduxState): boolean => {
  const person = getLoggedInPerson(state);
  if (person && person.personPermissionRoles && isListNotEmpty(person.personPermissionRoles)) {
    for (const personPermissionRole of person.personPermissionRoles) {
      if (personPermissionRole.permissionRole?.patientCarePlanAssignUnassign) {
        return true;
      }
    }
  }
  return false;
};

export const hasPatientClinicalDataInCareTeamPhiReadPermissionSelector = (state: ReduxState): boolean => {
  const person = getLoggedInPerson(state);
  if (person && person.personPermissionRoles && isListNotEmpty(person.personPermissionRoles)) {
    for (const personPermissionRole of person.personPermissionRoles) {
      if (personPermissionRole.permissionRole?.patientClinicalDataInCareTeamPhiRead) {
        return true;
      }
    }
  }
  return false;
};

export const hasTeamMemberAccountsCreatePermissionSelector = (state: ReduxState): boolean => {
  return hasClinicalAccountsCreatePermissionSelector(state) || hasNonClinicalAccountsCreatePermissionSelector(state) || hasOrgAdminAccountsCreatePermissionSelector(state);
};

export const getPersonPermissionRoleByIdSelector = (state: ReduxState): Record<string, PersonPermissionRole> => {
  const person: PersonState = getPersonState(state);
  return person.personPermissionRoleById;
};

export const getPermissionRoleByIdSelector = (state: ReduxState): Record<string, PermissionRole> => {
  const person: PersonState = getPersonState(state);
  return person.permissionRoleById;
};

export const getPermissionRoleTypeByIdSelector = (state: ReduxState): Record<string, PermissionRoleType> => {
  const person: PersonState = getPersonState(state);
  return person.permissionRoleTypeById;
};

export const getPersonPermissionRoleIdsByPersonIdSelector = (state: ReduxState): Record<string, string[]> => {
  const person: PersonState = getPersonState(state);
  return person.personPermissionRoleIdsByPersonId;
};

export const getPermissionRoleTypeNameByPersonIdSelector = (
  personId: string,
  personPermissionRoleIdsByPersonId: Record<string, string[]>,
  personPermissionRoleById: Record<string, PersonPermissionRole>,
  permissionRoleById: Record<string, PermissionRole>,
  permissionRoleTypeById: Record<string, PermissionRoleType>
): Nullable<string> => {
  const personPermissionRoleIds = personPermissionRoleIdsByPersonId[personId];
  for (const personPermissionRoleId of personPermissionRoleIds) {
    const personPermissionRole = personPermissionRoleById[personPermissionRoleId];
    if (personPermissionRole) {
      const permissionRole = permissionRoleById[personPermissionRole.permissionRoleId];
      if (permissionRole) {
        const permissionRoleType = permissionRoleTypeById[permissionRole.permissionRoleTypeId];
        if (permissionRoleType?.name) {
          return permissionRoleType.name;
        }
      }
    }
  }
};

export const hasAuthUserAdminPermissionSelector = (state: ReduxState): boolean => {
  const personPermissionRoleIdsByPersonId = getPersonPermissionRoleIdsByPersonIdSelector(state);
  const personPermissionRoleById = getPersonPermissionRoleByIdSelector(state);
  const permissionRoleById = getPermissionRoleByIdSelector(state);
  const authPersonId = getAuthPersonIdSelector(state);
  if (authPersonId) {
    const personPermissionRoleIds = personPermissionRoleIdsByPersonId[authPersonId];
    if (personPermissionRoleIds) {
      for (const personPermissionRoleId of personPermissionRoleIds) {
        const personPermissionRole = personPermissionRoleById[personPermissionRoleId];
        const permissionRole = permissionRoleById[personPermissionRole.permissionRoleId];
        if (permissionRole?.permissionRoleTypeId === PERMISSION_ROLE_ID_ADMIN) {
          return true;
        }
      }
    }
  }

  return hasCreateOrgPermissionSelector(state);
};

export const getPersonVitalsByPersonIdSelector = (state: ReduxState): Record<string, PersonVitals> => {
  const person: PersonState = getPersonState(state);
  return person.personVitalsByPersonId;
};
